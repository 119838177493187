//Utilities
export { default as DuAuthenticationUtilities } from './Utilities/DuAuthenticationUtilities';
export { default as DuCache } from './Utilities/DuCache';
export { default as DuCommonTranslations } from './Utilities/DuCommonTranslations';
export { default as DuDateUtilities } from './Utilities/DuDateUtilities';
export { default as DuExcelUtilities } from './Utilities/DuExcelUtilities';
export { default as DuGlossaryUtilities } from './Utilities/DuGlossaryUtilities';
export { default as DuIcons } from './Utilities/DuIcons';
export { default as DuIconSrc } from './Utilities/DuIconSrc';
export { default as DuLogger } from './Utilities/DuLogger';
export { default as DuPersonalizationUtilities } from './Utilities/DuPersonalizationUtilities';
export { default as DuThemeUtilities } from './Utilities/DuThemeUtilities';
export { default as DuUploadUtilities } from './Utilities/DuUploadUtilities';
export { default as DuValidationUtilities } from './Utilities/DuValidationUtilities';
//External Libraries
if (window) {
    window.Driscolls_React_Utilities_Version = '2.27.9';
    window.Versions = window.Versions || [];
    if (
        window.Versions.findIndex((v) => {
            return v.package === 'driscolls-react-utilities';
        }) < 0
    ) {
        window.Versions.push({
            package: 'driscolls-react-utilities',
            name: "Driscoll's React Utilities",
            description: "Driscoll's React Utilities",
            version: window.Driscolls_React_Utilities_Version
        });
    }
}
