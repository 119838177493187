import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Middleware } from 'one-ring';
import { DrcPageDataMaintenance, DrcDialog, DrcPageWarning, DrcButton, DrcDatePicker, DrcLoading, DrcTooltip } from 'driscolls-react-components';
import APIEndPoints from '../../services/api';
import { withAuth } from '@okta/okta-react';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import { setApplications } from '../../actions/ranchOverviewActions';
import RanchUtilities from '../../data/RanchUtilities';
import RefreshButton from '../RanchOverview/Holds/RefreshButton';

const PAGE_TITLE = 'No Application Records';
const PAGE_TYPE = 'getChemicalApplications';

const styles = (theme) => ({
    grid: {
        padding: 0,
        margin: 0,
        '& div[class^="DrcPanel-"]': {
            border: `none`
        }
    },
    refresh: {
        marginTop: '5px',
        position: 'relative',
        float: 'right'
    }
});

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

const NoApplication = (props) => {
    const { classes } = props;

    let saturday = new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()));

    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);
    const [weekendDate, setWeekendDate] = useState(saturday);
    const [addDialog, setAddDialog] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isWritable, setIsWritable] = useState('');

    useEffect(() => {
        async function isUserWritable() {
            let token = await props.auth.getAccessToken();
            setIsWritable(DuAuthenticationUtilities.IsInGroup(token, adminGroups));
        }
        isUserWritable();
    }, [props.auth]);

    useEffect(() => {}, [props.noApplication, props.ranch]);

    const dateFormatter = (value) => {
        var date = value || '';
        if (date.includes('T')) {
            date = date.split('T')[0];
        } else if (date.includes(' ')) {
            date = date.split(' ')[0];
        }
        date = DuDateUtilities.ToPrettyDate(date);
        return (
            <DrcTooltip tipText={date}>
                <span>{date}</span>
            </DrcTooltip>
        );
    };

    const dateTimeFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
                <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const columns = [
        {
            key: 'TransactionId',
            name: 'Transaction Id',
            width: 0,
            isRequired: false,
            isDisabled: true,
            isHidden: true
        },
        {
            key: 'WeekEndDate',
            name: 'Week Of',
            inputType: 'Date',
            sortDescendingFirst: true,
            width: 160,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateFormatter(row.WeekEndDate)
        },
        {
            key: 'DocumentId',
            name: 'Doc Id',
            width: 100,
            formatter: cellFormatter,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true
        },
        {
            key: 'CreatedBy',
            name: 'Created By',
            width: 140,
            isDisabled: true,
            isHidden: true,
            filter: true,
            formatter: cellFormatter
        },
        {
            key: 'CreatedDateTime',
            name: 'Created Date Time',
            width: 180,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.CreatedDateTime)
        },
        {
            key: 'ModifiedBy',
            name: 'Modified By',
            width: 0,
            isDisabled: true,
            isHidden: true,
            formatter: cellFormatter
        },
        {
            key: 'ModifiedDateTime',
            name: 'Modified Date Time',
            width: 0,
            isDisabled: true,
            isHidden: true
        }
    ];

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const refreshGrid = () => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.SetApplicationsDetails(token, props.ranch, props.setApplications);
        });
    };

    const isRecordExists = (date) => {
        return (props.noApplication || []).some((item) => {
            return new Date(item.WeekEndDate).getTime() === new Date(DuDateUtilities.FormatDateFromIso(new Date(date).toISOString())).getTime();
        });
    };

    const applicationRecordExists = (date) => {
        return (props.application || []).find((item) => {
            return new Date(item.WeekEndDate).getTime() === new Date(DuDateUtilities.FormatDateFromIso(new Date(date).toISOString())).getTime();
        });
    };

    const onAdd = (addObj) => {
        //check if any records exists for same weekend date
        if (isRecordExists(weekendDate)) {
            setOnError(true);
            showErrorMessage('No application record exists for the selected weekend date');
            return;
        }
        let record = applicationRecordExists(weekendDate);
        //check if application is already applied for the weekend date
        if (record) {
            setOnError(true);
            showErrorMessage(`${record.ApplicationType} record already exists for the selected weekend date.`);
            return;
        }

        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();

            let transactionInfo = {};
            transactionInfo.SourceUser = loggedInUser;
            transactionInfo.TransactionDateTime = date;
            transactionInfo.SourceSystem = 'FACTS'; //this may not be required

            let payload = {};
            payload.RanchNbr = props.ranch;
            payload.IsoEventDateTime = DuDateUtilities.ToIsoDate(weekendDate);
            payload.TransInfo = transactionInfo;

            //Get the last day of the week from for the week containing the event start date
            let lastDay = weekendDate.getDate() - weekendDate.getDay() + 6;

            //Get the weekend Date Obj
            let weekendDateObj = new Date(new Date(weekendDate).setDate(lastDay));

            //Get the weekend string in ISO format but not GMT/UTC/Zulu
            let weekendDateStr = DuDateUtilities.ToIsoDate(weekendDateObj, false);

            payload.WeekEndDate = weekendDateStr;

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.ADD_NO_APPLICATION, 'POST', payload, {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (data.status && data.status !== 201) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Added No Application Successfully! Please allow a minute or two for the results to show below');
                        setOnError(false);
                        setAddDialog(false);
                        refreshGrid();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to Add No Application Record!');
                    setOnError(true);
                });
        });
    };

    const onDelete = (row) => {
        //Remove below lines once API is ready
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';

            let weekendDate = new Date(row.EventStartDate);
            debugger;
            //Get the last day of the week from for the week containing the event start date
            let lastDay = weekendDate.getDate() - weekendDate.getDay() + 6;

            //Get the weekend Date Obj
            let weekendDateObj = new Date(new Date(weekendDate).setDate(lastDay));

            //Get the weekend string in ISO format but not GMT/UTC/Zulu
            let weekendDateStr = DuDateUtilities.ToIsoDate(weekendDateObj, false);

            let payload = {
                RanchNbr: row.RanchNumber,
                SourceDocumentId: row.TransactionId,
                IsoEventDateTime: DuDateUtilities.ToIsoDate(new Date(row.EventStartDate)) + 'Z',
                IsoRecordedDateTime: new Date(row.CreatedDateTime).toISOString(),
                TransInfo: {
                    TransactionDateTime: new Date().toISOString(),
                    SourceSystem: 'FACTS',
                    SourceUser: loggedInUser
                },
                CompanyId: 210,
                WeekEndDate: weekendDateStr
            };

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.ADD_NO_APPLICATION, 'DELETE', payload, {})
                .then((data) => {
                    showErrorMessage(`Deleted No Application Record Successfully! Please allow a minute or two for the results to show below`);
                    setOnError(false);
                    refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage(`Failed to Delete No Application Record!`);
                    setOnError(true);
                });
        });
    };

    const showAddDialog = () => {
        setWeekendDate(saturday);
        setIsValid(true);
        setAddDialog(true);
    };

    const onAddDialogClose = () => {
        setAddDialog(false);
    };

    const shouldDisableDate = (date) => {
        //disable all days except Saturday
        return date.getDay() !== 6;
    };

    const datepickerChange = (date) => {
        setWeekendDate(date);
        // check if date is null
        if (date) setIsValid(true);
        else setIsValid(false);
    };
    const selectedDate = () => {
        //select only upcoming Saturdays
        return new Date().getDay === 6 ? new Date() : new Date() + (6 - new Date().getDay);
    };

    return isWritable === '' ? (
        <DrcLoading />
    ) : (
        <div>
            <RefreshButton onClick={refreshGrid}></RefreshButton>
            <DrcPageDataMaintenance
                className={`${classes.grid}`}
                columns={columns}
                fullWidth={true}
                data={props.noApplication}
                type={PAGE_TITLE}
                textOptions={{ PageTitle: '', AddBtn: 'Add New' }}
                settings={{
                    EnableExport: false,
                    EnableAdd: isWritable,
                    EnableEdit: false,
                    EnableDelete: isWritable,
                    OverrideAdd: showAddDialog
                }}
                onAdd={onAdd}
                onDelete={onDelete}
            />
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            {addDialog ? (
                <DrcDialog
                    open={addDialog}
                    title={'Add New No Application Record'}
                    buttons={
                        <div>
                            <DrcButton isSecondary onClick={onAddDialogClose} floatRight>
                                Cancel
                            </DrcButton>
                            <DrcButton isPrimary onClick={onAdd} floatRight disabled={!isValid}>
                                Save
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcDatePicker
                                label={'For Week Of'}
                                required
                                value={weekendDate}
                                selectedDate={selectedDate}
                                onChange={datepickerChange}
                                shouldDisableDate={shouldDisableDate}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>
                </DrcDialog>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAdmin: state.masterReducer.isAdmin,
        ranch: state.ranchOverview.ranch,
        noApplication: state.ranchOverview.noApplication,
        application: state.ranchOverview.application,
        ranchOverview: state.ranchOverview
    };
};

const mapDispatchToProps = (dispatch) => ({
    setApplications: (data) => dispatch(setApplications(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(NoApplication)));
