import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { setPageTitleAction } from '../../actions/actions';
import { setCountryList } from '../../actions/masterActions';
import RanchUtilities from '../../data/RanchUtilities';
import { setCountryRestrictions } from '../../actions/ranchOverviewActions';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../services/api';
import { DrcPageDataMaintenance, DrcTooltip, DrcPageWarning, DrcDialog, DrcButton, DrcSelect, DrcDateTimePicker } from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import RefreshButton from '../RanchOverview/Holds/RefreshButton';

let styles = (theme) => ({
    grid: {
        padding: 0,
        margin: 0,
        '& div[class^="DrcPanel-"]': {
            border: `none`
        }
    }
});

const pageTitle = 'Country Restrictions';
const pageType = 'countryRestriction';

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

const CountryRestrictions = (props) => {
    const { classes, isMasterDataInitialized } = props;

    const [isWritable, setIsWritable] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);

    const [id, setId] = useState('');
    const [countries, setCountries] = useState([]);
    const [mrlBlocks, setMrlBlocks] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');

    const [addMode, setAddMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [prevStartDate, setPrevStartDate] = useState('');
    const [prevEndDate, setPrevEndDate] = useState('');

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const getRestrictions = () => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.SetRestrictions(token, props.ranch, props.setCountryRestrictions);
        });
        // get latest list of countries on refresh
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('countryList', token, APIEndPoints.COUNTRY_LIST, 'GET').then((data) => {
                if (data && data.status) {
                    showErrorMessage('Failed to fetch country list');
                    setOnError(true);
                }
                props.setCountryList(data.Results);
            });
        });
    };
    useEffect(getRestrictions, []);

    const isUserWritable = () => {
        props.auth.getAccessToken().then((token) => {
            setIsWritable(DuAuthenticationUtilities.IsInGroup(token, adminGroups));
        });
    };
    useEffect(isUserWritable, []);

    useEffect(() => {
        props.setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
        setMinDate(new Date(new Date().setDate(new Date().getDate() - 89)));
        setMaxDate(new Date(new Date().setDate(new Date().getDate() + 89)));
    }, [props, isMasterDataInitialized]);

    const dateTimeFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
                <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const defaultColumnProperties = {
        resizable: true
    };

    const columns = [
        {
            key: 'areaCode',
            name: 'Country',
            width: 100,
            inputType: 'Select',
            selectOptions: props.countryListOptions || [],
            isMulti: true,
            isRequired: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.areaCode)
        },
        {
            key: 'mrlBlock',
            name: 'Block Name',
            width: 100,
            inputType: 'Select',
            selectOptions: props.blocks || [],
            isRequired: true,
            isMulti: true,
            editDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.mrlBlock)
        },
        {
            key: 'startDate',
            name: 'Start Date',
            width: 180,
            inputType: 'Date',
            isRequired: true,
            defaultValue: DuDateUtilities.FormatDateFromIso(new Date().toISOString()),
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.startDate)
        },
        {
            key: 'endDate',
            name: 'End Date',
            width: 180,
            inputType: 'Date',
            isRequired: true,
            defaultValue: DuDateUtilities.FormatDateFromIso(new Date().toISOString()),
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.endDate)
        },
        {
            key: 'createdBy',
            name: 'Created By',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.createdBy)
        },
        {
            key: 'createdDateTime',
            name: 'Created Date Time',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.createdDateTime)
        },
        {
            key: 'modifiedBy',
            name: 'Modified By',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.modifiedBy)
        },
        {
            key: 'modifiedDateTime',
            name: 'Modified Date Time',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.modifiedDateTime)
        }
    ].map((c) => ({ ...c, ...defaultColumnProperties }));

    const onAdd = () => {
        let record = [];
        countries.forEach((areaCode) => {
            mrlBlocks.forEach((block) => {
                let exists = RanchUtilities.CheckIfCountryRestrictionExists(
                    block.value,
                    startDate,
                    endDate,
                    props.countryRestrictions,
                    areaCode.label
                );
                if (exists) {
                    record.push({ areaCode: areaCode.label, block: block.value });
                }
            });
        });

        if (record.length) {
            let records = '';
            record.forEach((el) => (records += `\n AreaCode: ${el.areaCode}, Block: ${el.block} \n`));
            setOnError(true);
            showErrorMessage(`Records already exists for ${records}`);
            return;
        }
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();

            let payload = {};

            let ranchBlocks = (mrlBlocks || []).map((el) => {
                return {
                    RanchNbr: props.ranch,
                    BlockDesignator: el.value,
                    BlockType: 'FOODSAFETY'
                };
            });
            let holdAreas = (countries || []).map((el) => {
                return el.label;
            });

            payload.ranchBlocks = ranchBlocks;
            payload.holdArea = holdAreas;
            payload.isoStartDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(startDate.toISOString())).toISOString(); // remove seconds;
            payload.isoEndDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(endDate.toISOString())).toISOString(); // remove seconds;
            payload.transInfo = {
                TransactionDateTime: date,
                SourceSystem: 'FACTS',
                SourceUser: loggedInUser
            };
            payload.companyId = '210';
            props.auth.getAccessToken().then((token) => {
                Middleware.Send(pageType, token, APIEndPoints.COUNTRY_RESTRICTION, 'POST', payload, { overrideResponseMapping: true })
                    .then((data) => {
                        if (data.status && data.status !== 201) {
                            showErrorMessage(data.message);
                            setOnError(true);
                        } else {
                            setOnError(false);
                            showErrorMessage('Added Country Restrictions Successfully. Please allow a minute or two for the results to show below');
                            getRestrictions();
                            closeAddDialog();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        showErrorMessage('Failed to Add country restrictions');
                        setOnError(true);
                    });
            });
        });
    };

    const onEdit = () => {
        let recordExists = RanchUtilities.CheckIfCountryRestrictionExists(
            mrlBlocks.value,
            startDate,
            endDate,
            (props.countryRestrictions || []).filter((el) => el.id !== id),
            countries.value
        );
        if (recordExists) {
            setOnError(true);
            showErrorMessage('Country Restrictions record already exists for selected date range');
            return;
        }
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();
            let payload = {};

            // Comments field isn't there in acceptance criteria not adding in payload
            payload.ranchBlocks = [
                {
                    RanchNbr: props.ranch,
                    BlockDesignator: mrlBlocks.value,
                    BlockType: 'FOODSAFETY',
                    SourceDocumentId: id
                }
            ];
            payload.holdArea = [countries.value];
            payload.isoStartDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(startDate.toISOString())).toISOString();
            payload.isoEndDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(endDate.toISOString())).toISOString(); // remove seconds;
            payload.transInfo = {
                TransactionDateTime: date,
                SourceSystem: 'FACTS',
                SourceUser: loggedInUser
            };
            payload.companyId = 210;

            Middleware.Send(pageType, token, APIEndPoints.COUNTRY_RESTRICTION, 'POST', payload, { overrideResponseMapping: true })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Edited Country Restrictions Successfully!');
                        setOnError(false);
                        getRestrictions();
                        closeAddDialog();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to edit country restrictions');
                    setOnError(true);
                });
        });
    };

    const onDelete = (delObj) => {
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();
            let payload = {};

            // Comments field isn't there in acceptance criteria not adding in payload
            payload.ranchNbr = props.ranch;
            payload.blockDesignator = delObj.mrlBlock;
            payload.blockType = 'FOODSAFETY';
            payload.sourceDocumentId = delObj.id;
            payload.holdArea = delObj.areaCode;
            payload.isoStartDateTime = new Date(delObj.startDate).toISOString();
            payload.isoEndDateTime = new Date(delObj.endDate).toISOString();
            payload.transInfo = {
                TransactionDateTime: date,
                SourceSystem: 'FACTS',
                SourceUser: loggedInUser
            };
            payload.companyId = 210;

            Middleware.Send(pageType, token, APIEndPoints.COUNTRY_RESTRICTION, 'DELETE', payload, { overrideResponseMapping: true })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Deleted Country Restrictions Successfully!');
                        setOnError(false);
                        getRestrictions();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to delete country restrictions');
                    setOnError(true);
                });
        });
    };

    const closeAddDialog = () => {
        setAddMode(false);
        setEditMode(false);
    };

    const showAddDialog = () => {
        setAddMode(true);
        setCountries([]);
        setMrlBlocks([]);
        setStartDate(new Date());
        setEndDate(new Date(new Date().getTime() + 60000));
        setIsValid(false);
    };

    const showEditDialoag = (row) => {
        setEditMode(true);
        setId(row.id);
        setCountries({ label: row.areaCode, value: row.areaCode });
        setMrlBlocks({ label: row.mrlBlock, value: row.mrlBlock });
        setStartDate(new Date(row.startDate));
        setEndDate(new Date(row.endDate));
        setPrevStartDate(new Date(row.startDate));
        setPrevEndDate(new Date(row.endDate));
        setIsValid(false);
    };

    const checkEdit = (event, field) => {
        let eventDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(event).toISOString())).getTime();
        let prevStartDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(prevStartDate).toISOString())).getTime();
        let prevEndDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(prevEndDate).toISOString())).getTime();

        if (field === 'startDate') {
            let endDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(endDate).toISOString())).getTime();
            if (prevStartDateTime === eventDateTime && prevEndDateTime === endDateTime) return setIsValid(false);
        } else if (field === 'endDate') {
            let startDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(startDate).toISOString())).getTime();
            if (prevStartDateTime === startDateTime && prevEndDateTime === eventDateTime) return setIsValid(false);
        }
        return setIsValid(true);
    };

    const handleOnChangeOfDialogFields = (event, field) => {
        if (field === 'country') {
            setCountries(event);
            setIsValid(event ? (event.length && mrlBlocks ? mrlBlocks.length : false && startDate && endDate) : false);
        } else if (field === 'mrlBlock') {
            setMrlBlocks(event);
            setIsValid(event ? countries && event.length && startDate && endDate : false);
        } else if (field === 'startDate') {
            setStartDate(event);
            if (event && event.getTime() > endDate.getTime()) {
                //add extra 1 min to end date
                setEndDate(new Date(event.getTime() + 60000));
            }
            setIsValid(event ? countries.length && mrlBlocks.length && event && endDate : false);
            if (editMode) {
                checkEdit(event, field);
            }
        } else if (field === 'endDate') {
            setEndDate(event);
            setIsValid(event ? countries.length && mrlBlocks.length && startDate && event : false);
            if (editMode) {
                checkEdit(event, field);
            }
        }
    };

    return isWritable === '' ? null : (
        <div>
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            <RefreshButton onClick={getRestrictions}></RefreshButton>
            <DrcPageDataMaintenance
                className={`${classes.grid}`}
                columns={columns}
                fullWidth={true}
                data={props.countryRestrictions}
                pageType={pageType}
                textOptions={{ PageTitle: '', AddBtn: 'Add New' }}
                settings={{
                    EnableExport: false,
                    ShowCount: false,
                    EnableAdd: isWritable,
                    EnableEdit: false,
                    EnableDelete: isWritable,
                    OverrideAdd: showAddDialog,
                    OverrideEdit: showEditDialoag
                }}
                type={pageTitle}
                fullHeightOffset={10}
                onAdd={onAdd}
                onEdit={onEdit}
                onDelete={onDelete}
            ></DrcPageDataMaintenance>

            {addMode || editMode ? (
                <DrcDialog
                    open={addMode || editMode}
                    title={`${editMode ? 'Edit type:' : 'Add New'}  ${pageTitle}`}
                    buttons={
                        <div>
                            <DrcButton isSecondary onClick={closeAddDialog} floatRight>
                                Cancel
                            </DrcButton>
                            <DrcButton isPrimary onClick={editMode ? onEdit : onAdd} floatRight disabled={!isValid}>
                                Save
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcSelect
                                label={'Country'}
                                required
                                value={countries}
                                isMulti={editMode ? false : true}
                                options={props.countryListOptions}
                                onChange={(e) => {
                                    handleOnChangeOfDialogFields(e, 'country');
                                }}
                                InputLabelProps={{ shrink: true }}
                                isDisabled={editMode}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcSelect
                                label={'Block Name'}
                                required
                                value={mrlBlocks}
                                isMulti={editMode ? false : true}
                                options={props.blocks}
                                onChange={(e) => {
                                    handleOnChangeOfDialogFields(e, 'mrlBlock');
                                }}
                                selectAllAllowed={props.blocks.length ? true : false}
                                InputLabelProps={{ shrink: true }}
                                isDisabled={editMode}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcDateTimePicker
                                label={'Start Date'}
                                required
                                value={startDate}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'startDate')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcDateTimePicker
                                label={'End Date'}
                                required
                                value={endDate}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'endDate')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                minDate={startDate}
                                maxDate={maxDate}
                            />
                        </div>
                    </div>
                </DrcDialog>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAdmin: state.masterReducer.isAdmin,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        ranch: state.ranchOverview.ranch,
        ranchOverview: state.ranchOverview,
        countryRestrictions: state.ranchOverview.countryRestrictions,
        countryListOptions: state.masterReducer.countryListOptions,
        blocks: state.ranchOverview.blocks
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setCountryRestrictions: (data) => dispatch(setCountryRestrictions(data)),
    setCountryList: (countryData) => dispatch(setCountryList(countryData))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CountryRestrictions)));
