import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { DrcSelect, DrcPageWarning, DrcTooltip, DrcPageDataMaintenance, DrcLoading } from 'driscolls-react-components';
import { setPageTitleAction } from '../../actions/actions';
import RanchUtilities from '../../data/RanchUtilities';
import { setMrlBlocks, clearMrlBlocks /*setApplications*/ } from '../../actions/ranchOverviewActions';
import { DuDateUtilities, DuAuthenticationUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../services/api';

let styles = (theme) => ({
    input: {
        marginTop: theme.spacing(3)
    },
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '350px'
        }
    }
});

const PAGE_TITLE = 'Mrl Blocks Maintenance';
const PAGE_TYPE = 'getBlockList';

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

const MrlBlock = (props) => {
    const { classes, isMasterDataInitialized } = props;
    const [selectedRanch, setSelectedRanch] = useState('');
    const [loggedInUser, setLoggedInUser] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [isWritable, setIsWritable] = useState('');

    const dateTimeFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
                <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const columns = [
        {
            key: 'blockDesignator',
            name: 'Block Designator',
            isRequired: true,
            validationType: 'regex',
            minLength: 2,
            maxLength: 2,
            filter: true,
            regex: RegExp(/^[a-zA-Z0-9]*$/),
            regexDescription: 'Only alphabets or numbers are allowed',
            columnTemplate: (row) => cellFormatter(row.blockDesignator)
        },
        {
            key: 'createdBy',
            name: 'Created By',
            isHidden: true,
            isDisabled: true,
            isRequired: false,
            width: 180,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.createdBy)
        },
        {
            key: 'createdDateTime',
            name: 'Created Date Time',
            isHidden: true,
            isDisabled: true,
            isRequired: false,
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.createdDateTime)
        },
        {
            key: 'modifiedBy',
            name: 'Modified By',
            isHidden: true,
            isDisabled: true,
            isRequired: false,
            width: 180,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.modifiedBy)
        },
        {
            key: 'modifiedDateTime',
            name: 'Modified Date Time',
            isHidden: true,
            isDisabled: true,
            isRequired: false,
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.modifiedDateTime)
        }
    ];

    useEffect(() => {
        props.setPageTitle(PAGE_TITLE);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        setRefresh(true);
        async function isUserWritable() {
            let token = await props.auth.getAccessToken();
            setIsWritable(DuAuthenticationUtilities.IsInGroup(token, adminGroups));
        }
        isUserWritable();
    }, [props, isMasterDataInitialized]);

    const handleKeyPress = (event) => {};

    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const handleChange = (option) => {
        setSelectedRanch(option);
        if (!option) return;
        mrlBlockDetails(option.value);
        //getApplicationData(option.value); uncomment when checking if app record exists to prevent user from deleting block
    };

    const mrlBlockDetails = (ranchNumber) => {
        props.auth.getAccessToken().then((token) => {
            props.clearMrlBlocks();
            RanchUtilities.SetBlockList(token, ranchNumber, props.setMrlBlocks);
            setLoggedInUser(DuAuthenticationUtilities.GetUserId(token) || 'Bad Token');
        });
    };

    const handleAdd = (addObj) => {
        let record = (props.mrlBlocks || []).find((record) => record.blockDesignator === addObj.blockDesignator);
        if (record) {
            setOnError(true);
            showErrorMessage('Block already exists. Please try with another name');
            return;
        }

        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();
            let payload = {};

            payload.ranchNumber = selectedRanch.value;
            payload.createdBy = loggedInUser;
            payload.createdDateTime = date;
            payload.modifiedBy = loggedInUser;
            payload.modifiedDateTime = date;
            payload.mrlBlocks = [
                {
                    BlockDesignator: addObj.blockDesignator,
                    IsActive: true
                }
            ];

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.BLOCK_LIST(selectedRanch.value), 'POST', payload, { overrideResponseMapping: true })
                .then((data) => {
                    if (data & data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        setOnError(false);
                        showErrorMessage('Added new Mrl block successfully.');
                        mrlBlockDetails(selectedRanch.value);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to add new Mrl block.');
                    setOnError(true);
                });
        });
    };

    //fetch application data
    /*const getApplicationData = (ranch) => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.SetApplicationsDetails(token, ranch, props.setApplications, showErrorMessage);
        });
    };

    //check if application record exists
    const checkIfAppExists = (records) => {
        let appRecords = [];
        let date = new Date(DuDateUtilities.FormatDateTimeFromIso(new Date().toISOString()));

        (records || []).forEach((record) => {
            let found = (props.application || []).find((el) =>
                el.ApplicationType === 'Application'
                    ? el.MrlBlock === record.blockDesignator && date >= new Date(el.EventStartDate) && date <= new Date(el.EventEndDate)
                    : el.MrlBlock === record.blockDesignator && date <= new Date(el.EventStartDate)
            );
            if (found) appRecords.push(found.MrlBlock);
        });
        return appRecords;
    };*/

    const handleDelete = (delObj) => {
        let records = Object.values(delObj);
        //prevent user from deleting block if application record exists
        /*let appRecords = checkIfAppExists(records);
        let appBlocks = appRecords.toString();

        // if application record already exists; can't delete the block
        if (appBlocks) {
            showErrorMessage(`Application/IntentToSpray record(s) exists for the blocks ${appBlocks}`);
            setOnError(true);
            return;
        }*/

        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();
            let payload = {};

            payload.ranchNumber = selectedRanch.value;
            payload.modifiedBy = loggedInUser;
            payload.modifiedDateTime = date;
            let mrlBlocks = records.map((record) => {
                return {
                    BlockDesignator: record.blockDesignator,
                    IsActive: false
                };
            });
            payload.mrlBlocks = mrlBlocks;
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.BLOCK_LIST(selectedRanch.value), 'POST', payload, {
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        setOnError(false);
                        showErrorMessage('Deleted Mrl Block successfully.');
                        mrlBlockDetails(selectedRanch.value);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to delete Mrl Block.');
                    setOnError(true);
                });
        });
    };

    const updateRefresh = () => {
        setRefresh(false);
    };

    const gridDataFormatter = (data) => {
        if (data.length === 0) {
            return [];
        }

        const { blockDesignator, createdBy, createdDateTime } = data[0];

        let modifiedData = [
            {
                blockDesignator: blockDesignator,
                createdBy: createdBy || loggedInUser,
                createdDateTime: createdDateTime || new Date().toISOString(),
                modifiedBy: loggedInUser,
                modifiedDateTime: new Date().toISOString()
            }
        ];
        return modifiedData;
    };

    return isWritable === '' ? (
        <DrcLoading></DrcLoading>
    ) : (
        <div>
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}

            <div style={{ margin: 20 }}>
                <DrcSelect
                    className={classes.input}
                    label={'Ranch Number'}
                    value={selectedRanch}
                    placeholder={'Type Ranch Number'}
                    onKeyDown={handleKeyPress}
                    onChange={(option) => handleChange(option)}
                    options={RanchUtilities.MapRanchesToOptions(props.ranches)}
                    noOptionsMessage={() => 'You have entered an invalid ranch number, please fix and try again'}
                    isClearable={true}
                    ignoreAccents={true}
                    isWindowedSelect={true}
                ></DrcSelect>
            </div>
            {selectedRanch && (
                <DrcPageDataMaintenance
                    className={classes.dialog}
                    textOptions={{ PageTitle: '', AddBtn: 'Add New' }}
                    settings={{
                        EnableExport: false,
                        ShowCount: false,
                        EnableAdd: isWritable,
                        EnableEdit: false,
                        EnableDelete: false,
                        EnableCheckBoxDelete: {
                            access: isWritable,
                            key: 'id'
                        }
                    }}
                    type={PAGE_TITLE}
                    columns={columns}
                    data={props.mrlBlocks}
                    refresh={refresh}
                    updateRefresh={updateRefresh}
                    fullWidth={true}
                    onAdd={handleAdd}
                    onCheckboxDelete={handleDelete}
                    gridDataFormatter={gridDataFormatter}
                    columnKey={'id'}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ranches: state.masterReducer.ranches,
        mrlBlocks: state.ranchOverview.mrlBlocks,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin,
        application: state.ranchOverview.application
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (payload) => dispatch(setPageTitleAction(payload)),
        setMrlBlocks: (payload) => dispatch(setMrlBlocks(payload)),
        clearMrlBlocks: () => dispatch(clearMrlBlocks())
        //setApplications: (data) => dispatch(setApplications(data)) uncomment when getting app data
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(MrlBlock)));
