import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { setPageTitleAction } from '../../actions/actions';
import { setCountryList } from '../../actions/masterActions';
import RanchUtilities from '../../data/RanchUtilities';
import { setPurRestrictions } from '../../actions/ranchOverviewActions';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../services/api';
import { DrcPageDataMaintenance, DrcTooltip, DrcPageWarning } from 'driscolls-react-components';
import RefreshButton from '../RanchOverview/Holds/RefreshButton';

let styles = (theme) => ({
    grid: {
        padding: 0,
        margin: 0,
        '& div[class^="DrcPanel-"]': {
            border: `none`
        }
    }
});

const pageTitle = 'PUR Restrictions';
const pageType = 'purRestriction';

const PurRestrictions = (props) => {
    const { classes, isMasterDataInitialized } = props;

    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const getRestrictions = () => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.SetPurRestrictions(token, props.ranch, props.setPurRestrictions);

            // get latest list of countries on refresh
            Middleware.Send('countryList', token, APIEndPoints.COUNTRY_LIST, 'GET').then((data) => {
                if (data && data.status) {
                    showErrorMessage('Failed to fetch country list');
                    setOnError(true);
                }
                props.setCountryList(data.Results);
            });
        });
    };
    useEffect(getRestrictions, []);

    useEffect(() => {
        props.setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
    }, [props, isMasterDataInitialized]);

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const defaultColumnProperties = {
        resizable: true
    };

    const columns = [
        {
            key: 'BlockDesignator',
            name: 'Block Name',
            width: 100,
            filter: true
        },
        {
            key: 'HoldArea',
            name: 'Country',
            width: 100,
            filter: true
        },
        {
            key: 'StartDate',
            name: 'Start Date',
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => cellFormatter(row.StartDate)
        },
        {
            key: 'EndDate',
            name: 'End Date',
            width: 180,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => cellFormatter(row.EndDate)
        }
    ].map((c) => ({ ...c, ...defaultColumnProperties }));

    return (
        <div>
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            <RefreshButton onClick={getRestrictions}></RefreshButton>
            <DrcPageDataMaintenance
                className={`${classes.grid}`}
                columns={columns}
                fullWidth={true}
                data={props.purRestrictions}
                pageType={pageType}
                textOptions={{ PageTitle: '' }}
                settings={{
                    EnableExport: true,
                    ShowCount: false,
                    EnableAdd: false,
                    EnableEdit: false,
                    EnableDelete: false
                }}
                type={pageTitle}
                fullHeightOffset={10}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAdmin: state.masterReducer.isAdmin,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        ranch: state.ranchOverview.ranch,
        ranchOverview: state.ranchOverview,
        purRestrictions: state.ranchOverview.purRestrictions,
        countryListOptions: state.masterReducer.countryListOptions,
        blocks: state.ranchOverview.blocks
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setPurRestrictions: (data) => dispatch(setPurRestrictions(data)),
    setCountryList: (countryData) => dispatch(setCountryList(countryData))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PurRestrictions)));
