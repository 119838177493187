import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../../actions/actions';
import { setCountryList } from '../../actions/masterActions';
import MasterDataUtilities from './../../data/MasterDataUtilities';
import { Middleware } from 'one-ring';
import { DrcButton, DrcInput, DrcDialog, DrcGrid, DrcTooltip, DrcPageWarning } from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import APIEndPoints from '../../services/api';

let styles = (theme) => ({
    input: {
        marginTop: theme.spacing(3)
    }
});

const pageTitle = 'Country Maintenance';
const PAGE_TYPE = 'countryList';

const Countries = (props) => {
    const { isMasterDataInitialized } = props;

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [showAddDialog, setAddDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [invalidISOCode, setInvalidISOCode] = useState(false);
    const [invalidName, setInvalidName] = useState(false);

    const cellFormatter = ({ value, row }) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const dateTimeFormatter = ({ value }) => {
        return (
            <DrcTooltip tipText={DuDateUtilities.FormatDateTimeFromIso(value) || ''}>
                <span>{DuDateUtilities.FormatDateTimeFromIso(value) || ''}</span>
            </DrcTooltip>
        );
    };

    const columns = [
        { key: 'id', name: 'Transaction Id', isRequired: false, width: 0, isDisabled: true, isHidden: true },
        { key: 'areaCode', name: 'Country ISO Code', isRequired: false, width: 130, formatter: cellFormatter },
        { key: 'abbreviation', name: 'Name', isRequired: false, width: 130, formatter: cellFormatter },
        { key: 'createdBy', name: 'Created By', isRequired: false, width: 180, formatter: cellFormatter },
        { key: 'createdAt', name: 'Created Date', isRequired: false, width: 180, formatter: dateTimeFormatter },
        { key: 'modifiedBy', name: 'Modified By', isRequired: false, width: 180, formatter: cellFormatter },
        { key: 'modifiedAt', name: 'Modified Date', isRequired: false, width: 180, formatter: dateTimeFormatter }
    ];

    useEffect(() => {
        props.setPageTitle(pageTitle);

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
    }, [props, isMasterDataInitialized]);

    const refreshList = () => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.COUNTRY_LIST, 'GET').then((data) => {
                if (data && data.status) {
                    showErrorMessage('Failed to fetch country list');
                    setOnError(true);
                }
                props.setCountryList(data.Results);
            });
        });
    };

    useEffect(refreshList, []);

    const openAddDialog = () => {
        setName('');
        setCode('');
        setAddDialog(true);
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const countryExists = () => {
        let value = '';

        let found = props.countryList.find((record) => record.areaCode.toLowerCase() === code.toLowerCase());
        if (found) return (value = 'country code');

        found = props.countryList.find((record) => record.abbreviation.toLowerCase() === name.toLowerCase());
        if (found) return (value = 'name');

        return value;
    };

    const onAdd = () => {
        if (name.length < 4) {
            showErrorMessage('Minimum characters for the name is 4');
            setOnError(true);
            return;
        }
        if (code.length < 3) {
            showErrorMessage('Minimum characters for the iso code is 3');
            setOnError(true);
            return;
        }
        let value = countryExists();
        if (value) {
            showErrorMessage(`Country already exists with same ${value}`);
            setOnError(true);
            return;
        }
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';

            let payload = {};

            payload.id = null;
            payload.areaCode = code;
            payload.abbreviation = name;
            payload.createdBy = loggedInUser;
            payload.isActive = null;
            payload.modifiedBy = loggedInUser;

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.COUNTRY_LIST, 'POST', payload, { overrideResponseMapping: true })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage('Failed to add country to the list');
                        setOnError(true);
                        return;
                    }
                    setAddDialog(false);
                    showErrorMessage('Added country to the list successfully');
                    setOnError(false);
                    refreshList();
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to add country to the list');
                    setOnError(true);
                });
        });
    };

    const handleChangeOfFields = (event, field) => {
        let allowedNameCharacters = RegExp(/^[a-zA-Z'-/\s]*$/);
        let allowedCodeCharacters = RegExp(/^[a-zA-Z]*$/);

        let value = event.target.value.trim();
        let nameValue = event.target.value;

        if (field === 'isoCode') {
            setCode(value.substring(0, 3));
            if (!allowedCodeCharacters.test(value.substring(0, 3))) {
                setIsValid(false);
                setInvalidISOCode(true);
            } else {
                setInvalidISOCode(false);
                setIsValid(value && name);
            }
        } else if (field === 'name') {
            setName(nameValue.substring(0, 255));
            if (!allowedNameCharacters.test(nameValue.substring(0, 255))) {
                setIsValid(false);
                setInvalidName(true);
            } else {
                setInvalidName(false);
                setIsValid(code && nameValue);
            }
        }
    };

    return (
        <div>
            {props.isAdmin ? (
                <div className="row" style={{ float: 'right' }}>
                    <DrcButton style={{ marginTop: '1.2rem' }} floatRight onClick={openAddDialog} isPrimary>
                        Add Country
                    </DrcButton>
                </div>
            ) : null}
            <span style={{ display: 'flex' }}>
                <h1>Countries</h1>
            </span>
            <DrcGrid rows={props.countryList} columns={columns} fullHeightOffset={10} />

            {showAddDialog ? (
                <DrcDialog
                    open={showAddDialog}
                    title={'Add new Country'}
                    buttons={
                        <div>
                            <DrcButton isPrimary onClick={onAdd} disabled={!isValid}>
                                Yes, we're good
                            </DrcButton>
                            <DrcButton
                                isSecondary
                                onClick={() => {
                                    setAddDialog(false);
                                    setCode('');
                                    setName('');
                                }}
                            >
                                Cancel
                            </DrcButton>
                        </div>
                    }
                >
                    <DrcInput
                        label={'Country ISO Code'}
                        value={code}
                        placeholder={'Please enter a code'}
                        onChange={(e) => {
                            handleChangeOfFields(e, 'isoCode');
                        }}
                        required
                        helperText={invalidISOCode ? 'Please enter valid ISO Code' : ''}
                        inputProps={{ minLength: 3, maxLength: 3 }}
                    ></DrcInput>
                    <DrcInput
                        label={'Name'}
                        value={name}
                        placeholder={'Please enter a Name'}
                        onChange={(e) => {
                            handleChangeOfFields(e, 'name');
                        }}
                        required
                        helperText={invalidName ? 'Please enter valid name' : ''}
                        inputProps={{ minLength: 4, maxLength: 255 }}
                    ></DrcInput>
                </DrcDialog>
            ) : null}
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        countryList: state.masterReducer.countryList,
        countryListOptions: state.masterReducer.countryListOptions,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isAdmin: state.masterReducer.isAdmin
    };
};
const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setCountryList: (countryData) => dispatch(setCountryList(countryData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(Countries)));
