import React, { useState, useEffect } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { DrcDialog, DrcButton, DrcPageWarning, DrcInput } from 'driscolls-react-components';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import APIEndPoints from './../../../../services/api';
import { setChemicalList, setChemical } from './../../../../actions/chemicalMaintenanceActions';

let styles = (theme) => {};

const EditChemical = (props) => {
    const { classes, open, onClose, chemical: selectedChemical, chemicalList } = props;

    let chemicalDefaultProps = { chemicalId: '', chemicalName: '', preHarvestIndex: 0, isActive: false };
    let confirmDialogDefaultProps = { isOpen: false, title: '', content: '', onConfirm: '', onCancel: '', onClose: '' };

    const [chemical, setChemical] = useState(chemicalDefaultProps);
    const [confirmDialog, setConfirmDialog] = useState(confirmDialogDefaultProps);
    const [disabled, setDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);

    const inputFields = [
        {
            name: 'chemicalName',
            label: 'Chemical Name',
            type: 'input'
        }
    ];

    const mapOptionToChemical = (option) => {
        return {
            chemicalId: option.value,
            chemicalName: option.label,
            isActive: option.isActive
        };
    };

    const mapChemicalToOption = (chemical) => {
        return {
            label: chemical.chemicalName,
            value: chemical.chemicalId,
            isActive: chemical.isActive
        };
    };

    useEffect(() => {
        setChemical(mapOptionToChemical(selectedChemical));
    }, [selectedChemical]);

    const handleChange = (name) => (event) => {
        setErrorMessage('');

        let chemicalObj = chemical;
        chemicalObj[name] =
            ((inputFields || []).filter((item) => item.name === name) || []).type === 'number' ? parseInt(event.target.value) : event.target.value;
        setChemical(chemicalObj);

        let disabled = JSON.stringify(chemical) === JSON.stringify(mapOptionToChemical(selectedChemical));
        setDisabled(disabled);
    };

    const handleKeyPress = (inputType) => (event) => {
        if (inputType !== 'number') return;

        const invalidKeys = ['e', '+', '-', '.'];
        if (invalidKeys.includes(event.key.toLowerCase())) {
            event.preventDefault();
        }
    };

    const isValidChemical = () => {
        const { chemicalName } = chemical;

        if (!(chemicalName || '').trim()) {
            showErrorMessage(`Chemical Name is mandatory`);
            setOnError(true);
            return false;
        }

        if ((chemicalList || []).some((item) => (item.label || '').toLowerCase() === chemicalName.toLowerCase())) {
            if (!(((selectedChemical || {}).label || '').toLocaleLowerCase() === chemicalName.toLocaleLowerCase())) {
                showErrorMessage(`Chemical Name must be unique`);
                setOnError(true);
                return false;
            }
        }

        return true;
    };

    const confirmNo = () => {
        setConfirmDialog(confirmDialogDefaultProps);
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const handleEditClose = () => {
        const chemicalOption = mapChemicalToOption(chemical);
        chemicalOption.forceUpdate = true; //refreshing chemical list to fetch all in case there is an edit at same time
        props.setChemical(chemicalOption);
        onClose(chemicalOption);
    };

    const handleEditChemical = () => {
        const payload = chemical;
        props.auth.getAccessToken().then((token) => {
            payload.lastChangedBy = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            payload.lastChangedDateTime = new Date().toISOString();

            Middleware.Send('editChemical', token, APIEndPoints.EDIT_CHEMICAL, 'POST', payload, {
                overrideRequestMapping: false,
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (!data || data.status || data.error) {
                        //the api does not send a status code on successful response
                        showErrorMessage('Invalid response from server');
                        setOnError(true);
                    } else {
                        setConfirmDialog(confirmDialogDefaultProps);
                        handleEditClose();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Unable to add chemical. Please try again');
                    setOnError(true);
                });
        });
    };

    const confirmEdit = () => {
        if (isValidChemical()) {
            setConfirmDialog({
                title: `Confirm Update`,
                content: `Are you sure you want to update the chemical?`,
                isOpen: true,
                onConfirm: handleEditChemical,
                onCancel: confirmNo,
                onClose: confirmNo
            });
        }
    };

    const renderConfirmDialog = () => {
        return (
            <DrcDialog
                className={classes.dialog}
                title={confirmDialog.title}
                open={confirmDialog.isOpen}
                onClose={() => confirmDialog.onCancel()}
                buttons={
                    <div className="row">
                        <div className="col-xs-6">
                            <DrcButton fullWidth isPrimary onClick={() => confirmDialog.onConfirm()}>
                                Yes
                            </DrcButton>
                        </div>
                        <div className="col-xs-6" style={{ paddingRight: '2rem' }}>
                            <DrcButton fullWidth isSecondary onClick={() => confirmDialog.onCancel()}>
                                No
                            </DrcButton>
                        </div>
                    </div>
                }
            >
                <span>{confirmDialog.content}</span>
            </DrcDialog>
        );
    };

    return (
        <div>
            <DrcDialog
                title="Edit Chemical"
                open={open}
                onClose={() => onClose()}
                buttons={
                    <div className="row">
                        <div className="col-xs-6">
                            <DrcButton fullWidth isPrimary onClick={() => confirmEdit()} disabled={disabled}>
                                Save
                            </DrcButton>
                        </div>
                        <div className="col-xs-6" style={{ paddingRight: '2rem' }}>
                            <DrcButton fullWidth isSecondary onClick={() => onClose()}>
                                Cancel
                            </DrcButton>
                        </div>
                    </div>
                }
            >
                <Grid container>
                    {inputFields.map((item) => {
                        return (
                            <DrcInput
                                key={item['name']}
                                type={item['type']}
                                label={item['label']}
                                defaultValue={chemical[item['name']]}
                                placeholder={item['label']}
                                onChange={handleChange(item.name)}
                                onKeyDown={handleKeyPress(item['type'])}
                                InputLabelProps={{ shrink: true }}
                                inputProps={item['type'] === 'number' ? { min: item['min'] } : {}}
                            ></DrcInput>
                        );
                    })}
                </Grid>
                {errorMessage && <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} />}
            </DrcDialog>
            {confirmDialog.isOpen && renderConfirmDialog()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        chemical: state.chemicalMaintenance.chemical,
        chemicalList: state.chemicalMaintenance.chemicalList,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setChemicalList: (data) => dispatch(setChemicalList(data)),
    setChemical: (data) => dispatch(setChemical(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(EditChemical)));
