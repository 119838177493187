import { combineReducers } from 'redux';
import { homeReducer } from './Home.reducer';
import chemicalMaintenanceReducer from './ChemicalMaintenance.reducer';
import { holdPeriodReducer } from './HoldPeriod.reducer';
import { safetyHoldsReducer } from './safetyHolds.reducer';
import masterReducer from './masterReducer';
import ranchOverviewReducer from './RanchOverview.reducer';
import { localizeReducer } from 'react-localize-redux';

const rootReducer = combineReducers({
    home: homeReducer,
    localize: localizeReducer,
    chemicalMaintenance: chemicalMaintenanceReducer,
    holdData: holdPeriodReducer,
    safetyHold: safetyHoldsReducer,
    masterReducer: masterReducer,
    ranchOverview: ranchOverviewReducer
});

export default rootReducer;
