import React, { useState, useEffect } from 'react';
import { Middleware } from 'one-ring';
import { connect } from 'react-redux';
import {
    DrcPageDataMaintenance,
    DrcTooltip,
    DrcDialog,
    DrcPageWarning,
    DrcButton,
    DrcDateTimePicker,
    DrcSelect,
    DrcInput,
    DrcLoading
} from 'driscolls-react-components';
import APIEndPoints from '../../services/api';
import { withStyles } from '@material-ui/core';
import { withAuth } from '@okta/okta-react';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import { setApplications } from '../../actions/ranchOverviewActions';
import { setChemicalDetails } from '../../actions/chemicalMaintenanceActions';
import chemicalMaintenanceUtilities from '../../data/chemicalMaintenanceUtilities';
import RanchUtilities from './../../data/RanchUtilities';
import RefreshButton from '../RanchOverview/Holds/RefreshButton';

const PAGE_TITLE = 'Applications / Intent To Spray';
const PAGE_TYPE = 'getChemicalApplications';

let styles = (theme) => ({
    grid: {
        padding: 0,
        margin: 0,
        '& div[class^="DrcPanel-"]': {
            border: `none`
        },
        '& .p-datatable-scrollable-wrapper': {
            width: '100%',
            display: 'block'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    warning: {
        color: theme.light.text.errorTitle
    },
    refresh: {
        marginTop: '5px',
        position: 'relative',
        float: 'right'
    }
});

const adminGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

const Application = (props) => {
    const { classes } = props;
    const [applicationType, setApplicationType] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [documentedId, setDocumentedId] = useState('');
    const [editedFields, setEditedFields] = useState([]);
    const [initialRow, setInitialRow] = useState({});
    const [mrlBlock, setMrlBlock] = useState([]);
    const [options, setOptions] = useState([]);
    const [chemicalId, setChemicalId] = useState('');
    const [treatedArea, setTreatedArea] = useState('');
    const [areaType, setAreaType] = useState('');
    const [ratePerArea, setRatePerArea] = useState('');
    const [units, setUnits] = useState('');
    const [eventStartDate, setStartDate] = useState('');
    const [eventEndDate, setEndDate] = useState('');
    const [oneYearInThePast, setOneYearInThePast] = useState('');
    const [todaysDate, setTodaysDate] = useState('');
    const [invalidRatePerArea, setInvalidRatePerArea] = useState(false);
    const [invalidTreatedArea, setInvalidTreatedArea] = useState(false);
    const [comments, setComments] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);
    const [addDialog, setAddDialog] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isWritable, setIsWritable] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [noActionTransactions, setNoActionTransactions] = useState([]);

    useEffect(() => {
        async function isUserWritable() {
            let token = await props.auth.getAccessToken();
            setIsWritable(DuAuthenticationUtilities.IsInGroup(token, adminGroups));
        }
        isUserWritable();
        let enableCurrentWeek = new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()));
        setEnableCurrentWeek(enableCurrentWeek);
        setOneYearInThePast(new Date(new Date().setDate(new Date().getDate() - 364)));
        setTodaysDate(new Date(new Date().setDate(new Date().getDate())));
        setStartDate(new Date(new Date().setDate(new Date().getDate()))); //set start date to today
        setEndDate(new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()))); //set to end date of the week by default
        setOptions(props.blocks || []);
        setRefresh(true);
    }, [props.blocks, props.auth]);

    const dateFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.FormatDateFromIso(value) : ''}>
                <span>{value ? DuDateUtilities.FormatDateFromIso(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const dateTimeFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
                <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const columns = [
        {
            key: 'ApplicationType',
            name: 'Type',
            width: 140,
            inputType: 'Select',
            selectOptions: props.applicationTypeOptions,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ApplicationType)
        },
        {
            key: 'DocumentId',
            name: 'Doc Id',
            width: 80,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.DocumentId)
        },
        {
            key: 'MrlBlock',
            name: 'MRL Block',
            inputType: 'Select',
            selectOptions: props.ranchOverview.blocks || [],
            filter: true,
            columnTemplate: (row) => cellFormatter(row.MrlBlock)
        },
        {
            key: 'ChemicalId',
            name: 'Chemical Id',
            width: 0,
            inputType: 'Select',
            selectOptions: props.chemicalList || [],
            isRequired: true
        },
        {
            key: 'ChemicalName',
            name: 'Chemical Name',
            width: 140,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ChemicalName)
        },
        {
            key: 'EventStartDate',
            name: 'Event Start Date',
            width: 180,
            inputType: 'Date',
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.EventStartDate)
        },
        {
            key: 'EventEndDate',
            name: 'Event End Date',
            width: 180,
            inputType: 'Date',
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.EventEndDate)
        },
        {
            key: 'TreatedArea',
            name: 'Treated Area',
            width: 100,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.TreatedArea)
        },
        {
            key: 'AreaType',
            name: 'Area Type',
            inputType: 'Select',
            selectOptions: props.areaTypeOptions || [],
            filter: true,
            columnTemplate: (row) => cellFormatter(row.AreaType)
        },
        {
            key: 'RatePerArea',
            name: 'Rate Per Area',
            width: 120,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.RatePerArea)
        },
        {
            key: 'Units',
            name: 'Units',
            inputType: 'Select',
            selectOptions: props.unitOptions || [],
            filter: true,
            columnTemplate: (row) => cellFormatter(row.Units)
        },
        {
            key: 'Comments',
            name: 'Comments',
            width: 140,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.Comments)
        },
        {
            key: 'CreatedBy',
            name: 'Created By',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.CreatedBy)
        },
        {
            key: 'CreatedDateTime',
            name: 'Created Date Time',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.CreatedDateTime)
        },
        {
            key: 'ModifiedBy',
            name: 'Modified By',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.ModifiedBy)
        },
        {
            key: 'ModifieddateTime',
            name: 'Modified Date Time',
            width: 180,
            isRequired: false,
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.ModifieddateTime)
        },
        {
            key: 'WeekEndDate',
            name: 'Weekend Date',
            width: 0,
            inputType: 'Date',
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateFormatter(row.WeekEndDate)
        }
    ];

    const refreshGrid = () => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.SetApplicationsDetails(token, props.ranch, props.setApplications);
        });

        setNoActionTransactions([]);
    };

    const showAddDialog = () => {
        //set empty values to add new chemical application record
        setApplicationType('');
        setMrlBlock('');
        setChemicalId('');
        setAreaType('');
        setUnits('');
        setTreatedArea('');
        setRatePerArea('');
        setComments('');
        setIsValid(false);
        setAddDialog(true);
        setEditMode(false);
        setStartDate(new Date()); // set to todays date by default
        setEndDate(new Date()); //set to todays date by default
    };

    const onAddDialogClose = () => {
        setAddDialog(false);
        setEditedFields([]);
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const getCommodities = (chemicalId) => {
        props.auth.getAccessToken().then((token) => {
            chemicalMaintenanceUtilities.setChemicalDetails(token, APIEndPoints.GET_CHEMICAL_DETAILS(chemicalId), props.setChemicalDetails);
        });
    };

    const checkCommodityAssociation = () => {
        let record = (props.chemicalCommodity || []).find((record) => record.ChemicalCommodityId === (props.ranchDetails || {}).commodityId);
        if ((record || {}).IsActive) return false;
        return true;
    };

    const noApplicationRecordExists = (weekendDate) => {
        return (props.noApplication || []).find((record) => {
            return (
                new Date(record.WeekEndDate).getTime() === new Date(DuDateUtilities.FormatDateFromIso(new Date(weekendDate).toISOString())).getTime()
            );
        });
    };

    const onAdd = () => {
        // check if chemical associated with commodity for the ranch
        let record = checkCommodityAssociation();
        if (record) {
            setOnError(true);
            showErrorMessage(`Chemical is not associated with commodity. Please select chemical which is associated with the ranch's commodity`);
            return;
        }
        // adding blocks condition to check for duplicate records
        let filteredNOIRecords = props.application.filter((el) => el.ApplicationType === 'IntentToSpray');
        let blockNOIExists = (mrlBlock || []).some(
            (el) => ((filteredNOIRecords || []).find((record) => record.MrlBlock === el.value) || {}).MrlBlock
        );
        if (applicationType.value === 'IntentToSpray') {
            let duplicateNOI = props.application.some(
                (record) =>
                    record.ApplicationType === 'IntentToSpray' &&
                    blockNOIExists &&
                    DuDateUtilities.FormatDateTimeFromIso(record.EventStartDate) ===
                        DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())
            );
            if (duplicateNOI) {
                showErrorMessage(`You already have an Intent To Spray in place for one or more of the selected blocks during this time frame`);
                setOnError(true);
                return;
            }
            // adding blocks condition to check for duplicate records
            let filteredPURRecords = props.application.filter((el) => el.ApplicationType === 'Application');
            let blockPURExists = (mrlBlock || []).some(
                (el) => ((filteredPURRecords || []).find((record) => record.MrlBlock === el.value) || {}).MrlBlock
            );
            let clashWithApp = props.application.some(
                (record) =>
                    record.ApplicationType === 'Application' &&
                    blockPURExists &&
                    new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).getTime() >=
                        new Date(DuDateUtilities.FormatDateTimeFromIso(record.EventStartDate)).getTime() &&
                    new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).getTime() <=
                        new Date(DuDateUtilities.FormatDateTimeFromIso(record.EventEndDate)).getTime()
            );

            if (clashWithApp) {
                showErrorMessage(
                    `Cannot create a new Intent to Spray. You already have an Application in place for one or more of the selected blocks during this time frame`
                );
                setOnError(true);
                return;
            }
        }

        if (applicationType.value === 'Application') {
            // check if start date, end date are equal
            if (
                new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).getTime() >=
                new Date(DuDateUtilities.FormatDateTimeFromIso(eventEndDate.toISOString())).getTime()
            ) {
                setOnError(true);
                showErrorMessage('End date time should always be greater than Start date time');
                return;
            }

            let isExists = (mrlBlock || []).some((el) =>
                RanchUtilities.CheckIfPURExists(
                    el.value,
                    chemicalId.value,
                    eventStartDate,
                    eventEndDate,
                    props.application.filter((el) => el.ApplicationType === 'Application')
                )
            );
            if (isExists) {
                showErrorMessage(
                    `Cannot create a new Application. You already have an Application in place for one or more of the selected blocks during this time frame`
                );
                setOnError(true);
                return;
            }
        }
        let endDateDay = 6 - new Date(eventEndDate).getDay();
        let startDateDay = 6 - new Date(eventStartDate).getDay();
        let weekendDate =
            applicationType.value === 'Application'
                ? new Date(new Date(eventEndDate).setTime(new Date(eventEndDate).getTime() + endDateDay * 86400 * 1000)) //set to the saturday of the week of the selected end date for PUR
                : new Date(new Date(eventStartDate).setTime(new Date(eventStartDate).getTime() + startDateDay * 86400 * 1000)); //set to the saturday of the week of the selected start date for NOI
        // check if no application record already exists for same weekend date
        let noApplicationRecord = noApplicationRecordExists(weekendDate);
        if (noApplicationRecord) {
            setOnError(true);
            showErrorMessage(`NoApplication record already exists for the selected weekend date.`);
            return;
        }

        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();
            let endDateTime =
                applicationType.value === 'Application'
                    ? new Date(DuDateUtilities.FormatDateTimeFromIso(eventEndDate.toISOString())).toISOString()
                    : null;

            let payload = {};

            let ranchBlocks = mrlBlock.map((item) => {
                return {
                    RanchNbr: props.ranch,
                    BlockDesignator: item.value,
                    BlockType: 'FOODSAFETY'
                };
            });

            payload.ApplicationType = applicationType.value;
            payload.RanchBlocks = ranchBlocks;
            payload.Applications = [
                {
                    ActiveIngredients: [
                        {
                            ChemicalId: chemicalId.value,
                            SourceAiName: props.chemicalNameMapping[chemicalId.value],
                            CommonAiName: '',
                            PpisCode: '',
                            Percentage: '',
                            RegisteredCommodityTypes: []
                        }
                    ],
                    TradeName: '',
                    Rate: {
                        Qty: parseFloat(ratePerArea),
                        Uom: units.value,
                        RatePerArea: parseFloat(ratePerArea)
                    },
                    Dilution: {
                        Qty: null,
                        Uom: null
                    },
                    TotalApplied: '',
                    Density: null,
                    ProductTypes: [],
                    TreatedArea: parseFloat(treatedArea)
                }
            ];

            payload.EventSource = null;
            payload.SourceBatchId = null;
            payload.SourceDocumentId = null;
            payload.IsoStartDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).toISOString();
            payload.IsoEndDateTime = endDateTime;

            //Get the last day of the week from for the week containing the event start date
            let lastDay = eventStartDate.getDate() - eventStartDate.getDay() + 6;

            //Get the weekend Date Obj
            let weekendDate = new Date(new Date(eventStartDate).setDate(lastDay));

            //Get the weekend string in ISO format but not GMT/UTC/Zulu
            let weekendDateStr = DuDateUtilities.ToIsoDate(weekendDate, false);

            payload.WeekEndDate = weekendDateStr;

            payload.IsoRecordedDateTime = new Date(DuDateUtilities.FormatDateTimeFromIso(weekendDate.toISOString())).toISOString();
            payload.AreaType = areaType.label;
            payload.Comments = comments;
            payload.TransInfo = {};
            payload.TransInfo.TransactionDateTime = date;
            payload.TransInfo.SourceSystem = 'FACTS';
            payload.TransInfo.SourceUser = loggedInUser;
            payload.CompanyId = null;

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.ADD_APPLICATION, 'POST', payload, {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (data.status && data.status !== 201) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage(
                            `Added ${applicationType.label} Successfully ${
                                eventStartDate.getDate() < new Date().getDate() ? 'to the past dates' : ''
                            }!  Please allow a minute or two for the results to show below`
                        );
                        setOnError(false);
                        refreshGrid();
                        setAddDialog(false);
                    }
                })
                .catch((error) => {
                    showErrorMessage(`Failed to Add ${applicationType.label} Record!`);
                    setOnError(true);
                });
        });
    };

    const onEdit = () => {
        if (applicationType.value === 'IntentToSpray') {
            let duplicateNOI = props.application.some(
                (record) =>
                    record.ApplicationType === 'IntentToSpray' &&
                    record.TransactionId !== transactionId &&
                    DuDateUtilities.FormatDateTimeFromIso(record.EventStartDate) ===
                        DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())
            );
            if (duplicateNOI) {
                showErrorMessage(`You already have an Intent To Spray in place for one or more of the selected blocks during this time frame`);
                setOnError(true);
                return;
            }
            let clashWithApp = props.application.some(
                (record) =>
                    record.ApplicationType === 'Application' &&
                    new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).getTime() >=
                        new Date(DuDateUtilities.FormatDateTimeFromIso(record.EventStartDate)).getTime() &&
                    new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).getTime() <=
                        new Date(DuDateUtilities.FormatDateTimeFromIso(record.EventEndDate)).getTime()
            );

            if (clashWithApp) {
                showErrorMessage(
                    `Cannot edit this Intent to Spray. You already have an Application in place for one or more of the selected blocks during this time frame`
                );
                setOnError(true);
                return;
            }
        }
        if (applicationType.value === 'Application') {
            let isExists = RanchUtilities.CheckIfPURExists(
                mrlBlock.value,
                chemicalId.value,
                eventStartDate,
                eventEndDate,
                props.application.filter((el) => el.ApplicationType === 'Application' && el.TransactionId !== transactionId)
            );

            if (isExists) {
                showErrorMessage(
                    `Cannot edit this Application. You already have an Application in place for one or more of the selected blocks during this time frame`
                );
                setOnError(true);
                return;
            }
            // check if start date, end date are equal
            if (
                new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).getTime() >=
                new Date(DuDateUtilities.FormatDateTimeFromIso(eventEndDate.toISOString())).getTime()
            ) {
                setOnError(true);
                showErrorMessage('End date time should always be greater than Start date time');
                return;
            }
        }
        let endDateDay = 6 - new Date(eventEndDate).getDay();
        let startDateDay = 6 - new Date(eventStartDate).getDay();
        let weekendDate =
            applicationType.value === 'Application'
                ? new Date(new Date(eventEndDate).setTime(new Date(eventEndDate).getTime() + endDateDay * 86400 * 1000)) //set to the saturday of the week of the selected end date for PUR
                : new Date(new Date(eventStartDate).setTime(new Date(eventStartDate).getTime() + startDateDay * 86400 * 1000)); //set to the saturday of the week of the selected start date for NOI
        // check if no application record already exists for same weekend date
        let record = noApplicationRecordExists(weekendDate);
        if (record) {
            setOnError(true);
            showErrorMessage(`NoApplication record already exists for the selected weekend date.`);
            return;
        }
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let endDateTime =
                applicationType.value === 'Application'
                    ? new Date(DuDateUtilities.FormatDateTimeFromIso(eventEndDate.toISOString())).toISOString()
                    : null;
            var payload = {
                ApplicationType: applicationType.value,
                TransactionId: transactionId + '',
                RanchBlocks: [
                    {
                        RanchNbr: props.ranch,
                        BlockDesignator: mrlBlock.value,
                        BlockType: 'FOODSAFETY'
                    }
                ],
                Applications: [
                    {
                        ActiveIngredients: [
                            {
                                ChemicalId: chemicalId.value,
                                SourceAiName: props.chemicalNameMapping[chemicalId.value],
                                CommonAiName: '',
                                PpisCode: '',
                                Percentage: '',
                                RegisteredCommodityTypes: []
                            }
                        ],
                        TradeName: '',
                        Rate: {
                            Qty: '2',
                            Uom: units.value,
                            RatePerArea: ratePerArea || ''
                        },
                        Dilution: {
                            Qty: '',
                            Uom: units.value
                        },
                        TotalApplied: null,
                        Density: '',
                        ProductTypes: [],
                        TreatedArea: treatedArea || ''
                    }
                ],
                SourceBatchId: null,
                SourceDocumentId: documentedId,
                IsoStartDateTime: new Date(DuDateUtilities.FormatDateTimeFromIso(eventStartDate.toISOString())).toISOString(),
                IsoEndDateTime: endDateTime,
                IsoRecordedDateTime: new Date(DuDateUtilities.FormatDateTimeFromIso(weekendDate.toISOString())).toISOString(),
                AreaType: areaType.label,
                Comments: comments,
                PropertiesEdited: editedFields, //Modified/Edited FieldNames
                TransInfo: {
                    TransactionDateTime: new Date().toISOString(),
                    SourceSystem: 'FACTS',
                    SourceUser: loggedInUser
                },
                CompanyId: '210'
            };

            //Get the last day of the week from for the week containing the event start date
            let lastDay = eventStartDate.getDate() - eventStartDate.getDay() + 6;

            //Get the weekend Date Obj
            let weekendDateObj = new Date(new Date(eventStartDate).setDate(lastDay));

            //Get the weekend string in ISO format but not GMT/UTC/Zulu
            let weekendDateStr = DuDateUtilities.ToIsoDate(weekendDateObj, false);

            payload.WeekEndDate = weekendDateStr;

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.ADD_APPLICATION, 'PUT', payload, {
                overrideResponseMapping: true,
                overrideRequestMapping: true
            })
                .then((data) => {
                    setAddDialog(false);
                    showErrorMessage(
                        `Edited the ${applicationType.label} Record Successfully! Please allow a minute or two for the results to show below`
                    );
                    setOnError(false);
                })
                .catch((error) => {
                    showErrorMessage(`Failed to edit the ${applicationType.label} Record!`);
                    setOnError(true);
                    setAddDialog(false);
                    refreshGrid();
                });
        });
    };

    const filterEditedFields = (updatedFields, field) => {
        return updatedFields.filter((item) => {
            return item !== field;
        });
    };

    const checkEdit = (field, value) => {
        var updatedFields = editedFields;
        if (!value) {
            setIsValid(false);
            updatedFields = filterEditedFields(updatedFields, field);
        } else {
            if (Array.isArray(value)) {
                if (value.length > 1) {
                    updatedFields.push(field);
                } else if (value.length === 1) {
                    let found = value.find((item) => item.value === initialRow[field]);
                    if (!found) {
                        updatedFields.push(field);
                    } else {
                        updatedFields = filterEditedFields(updatedFields, field);
                    }
                }
            } else {
                if (value instanceof Date) {
                    let newValue = new Date(value).toISOString().split('T')[0];
                    let rowValue = new Date(initialRow[field]).toISOString().split('T')[0];
                    if (rowValue !== newValue) {
                        updatedFields.push(field);
                    } else if (rowValue === newValue) {
                        updatedFields = filterEditedFields(updatedFields, field);
                    }
                } else if (initialRow[field] && initialRow[field].toString() !== value.toString()) {
                    updatedFields.push(field);
                    // setEditedFields(updatedFields);
                } else if (initialRow[field] && initialRow[field].toString() === value.toString()) {
                    updatedFields = filterEditedFields(updatedFields, field);
                    // setEditedFields(updatedFields);
                } else if (!initialRow[field]) {
                    updatedFields.push(field);
                }
            }
        }
        var uniqueItems = [...new Set(updatedFields)];

        setEditedFields(uniqueItems);
        setIsValid(uniqueItems.length);
    };
    //const [enablePastDate, setEnablePastDate] = useState('');
    const [enableCurrentWeek, setEnableCurrentWeek] = useState('');
    const handleOnChangeOfDialogFields = (event, field, isMulti) => {
        if (field === 'ApplicationType' || field === 'ChemicalId' || field === 'AreaType' || field === 'Units') {
            if (field === 'ApplicationType') {
                setApplicationType(event);
                //let enablePastDate = new Date(new Date().setDate(new Date().getDate() - 180));
                let enableCurrentWeek = new Date(new Date().setDate(new Date().getDate() + 6 - new Date().getDay()));
                let eventStartDate = new Date();

                //setEnablePastDate(enablePastDate);
                setEnableCurrentWeek(enableCurrentWeek);
                // add extra 1 min to end date for application type
                setEndDate(new Date(eventStartDate.getTime() + 60000));

                if (event.label !== 'Application') {
                    setStartDate(eventStartDate);
                    setEndDate(enableCurrentWeek);
                }

                if (editMode) {
                    checkEdit(field, event.value);
                } else {
                    setIsValid(
                        event &&
                            ratePerArea &&
                            treatedArea &&
                            (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                            areaType &&
                            units &&
                            chemicalId &&
                            eventStartDate &&
                            eventEndDate
                    );
                }
            } else if (field === 'AreaType') {
                setAreaType(event);

                if (editMode) {
                    checkEdit(field, event.label);
                } else {
                    setIsValid(
                        applicationType &&
                            ratePerArea &&
                            treatedArea &&
                            (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                            event &&
                            units &&
                            chemicalId &&
                            eventStartDate &&
                            eventEndDate
                    );
                }
            } else if (field === 'Units') {
                setUnits(event);
                if (editMode) {
                    checkEdit(field, event.label);
                } else {
                    setIsValid(
                        applicationType &&
                            ratePerArea &&
                            treatedArea &&
                            (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                            areaType &&
                            event &&
                            chemicalId &&
                            eventStartDate &&
                            eventEndDate
                    );
                }
            } else if (field === 'ChemicalId') {
                setChemicalId(event);
                getCommodities(event.value);
                setIsValid(
                    applicationType &&
                        ratePerArea &&
                        treatedArea &&
                        (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                        areaType &&
                        units &&
                        event &&
                        eventStartDate &&
                        eventEndDate
                );
            }
        } else if (field === 'MrlBlock') {
            setMrlBlock(event);

            if (editMode) {
                checkEdit(field, event ? event : []);
            } else
                setIsValid(
                    applicationType &&
                        ratePerArea &&
                        treatedArea &&
                        (event ? (event.length ? true : false) : false) && // updated check for mrl block
                        areaType &&
                        units &&
                        chemicalId &&
                        eventStartDate &&
                        eventEndDate
                );
        } else if (field === 'TreatedArea') {
            setTreatedArea(event.target.value);
            let valid = RegExp(/^[0-9]*(\.[0-9]*)?$/);
            if (!valid.test(event.target.value) || isNaN(Number(event.target.value)) || event.target.value < 0.01 || event.target.value > 999.9) {
                setInvalidTreatedArea(true);
            } else {
                setInvalidTreatedArea(false);
            }

            if (editMode) {
                checkEdit(field, event.target.value);
            } else {
                setIsValid(
                    applicationType &&
                        ratePerArea &&
                        event.target.value &&
                        (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                        areaType &&
                        units &&
                        chemicalId &&
                        eventStartDate &&
                        eventEndDate
                );
            }
        } else if (field === 'RatePerArea') {
            setRatePerArea(event.target.value);
            let valid = RegExp(/^[0-9]*(\.[0-9]*)?$/);

            if (!valid.test(event.target.value) || isNaN(Number(event.target.value)) || event.target.value < 0.01 || event.target.value > 999.9) {
                setInvalidRatePerArea(true);
            } else {
                setInvalidRatePerArea(false);
            }

            if (editMode) {
                checkEdit(field, event.target.value);
            } else {
                setIsValid(
                    applicationType &&
                        event.target.value &&
                        treatedArea &&
                        (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                        areaType &&
                        units &&
                        chemicalId &&
                        eventStartDate &&
                        eventEndDate
                );
            }
        } else if (field === 'EventStartDate') {
            setStartDate(event);
            if (
                event &&
                applicationType.value === 'Application' &&
                new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(event).toISOString())).getTime() >=
                    new Date(DuDateUtilities.FormatDateTimeFromIso(new Date(eventEndDate).toISOString())).getTime()
            ) {
                //add extra 1 min to end date
                setEndDate(new Date(event.getTime() + 60000));
            }
            if (editMode) {
                // if any date field is null disable save
                if (!event || (!eventEndDate && applicationType.value === 'Application')) {
                    setIsValid(false);
                } else {
                    checkEdit(field, event);
                }
            } else
                setIsValid(
                    applicationType &&
                        ratePerArea &&
                        treatedArea &&
                        (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                        areaType &&
                        units &&
                        chemicalId &&
                        event &&
                        (applicationType.value === 'Application' ? eventEndDate : true)
                );
        } else if (field === 'EventEndDate') {
            if (event && event.getTime() < eventStartDate) {
                setStartDate(event);
            }
            setEndDate(event);

            if (editMode) {
                // if any date field is null disable save
                if (!event || !eventStartDate) {
                    setIsValid(false);
                } else {
                    checkEdit(field, event);
                }
            } else {
                setIsValid(
                    applicationType &&
                        ratePerArea &&
                        treatedArea &&
                        (mrlBlock ? (mrlBlock.length ? true : false) : false) && // updated check for mrl block
                        areaType &&
                        units &&
                        chemicalId &&
                        eventStartDate &&
                        event
                );
            }
        } else if (field === 'Comments') {
            if (editMode) {
                checkEdit(field, event.target.value);
            }

            setComments(event.target.value);
        }
    };

    const showEditDialog = (row) => {
        setInitialRow(row);
        setEditedFields([]);
        setApplicationType({ label: row.ApplicationType, value: row.ApplicationType });
        setDocumentedId(row.DocumentId);
        setTransactionId(row.TransactionId);
        setChemicalId({ label: row.ChemicalName, value: row.ChemicalId });
        setMrlBlock({ label: row.MrlBlock, value: row.MrlBlock });
        setAreaType({ label: row.AreaType, value: row.AreaTypeId });
        setUnits({ label: row.Units, value: row.Units });
        setTreatedArea(row.TreatedArea);
        setRatePerArea(row.RatePerArea);
        setComments(row.Comments);
        setIsValid(false);
        setStartDate(new Date((row.EventStartDate || '').toLowerCase().includes('z') ? row.EventStartDate : row.EventStartDate + 'Z'));
        setEndDate(new Date((row.EventEndDate || '').toLowerCase().includes('z') ? row.EventEndDate : row.EventEndDate + 'Z'));
        setAddDialog(true);
        setEditMode(true);
    };

    const onDelete = (delObj) => {
        let records = Object.values(delObj);
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();
            let payload = {};
            payload.RanchNbr = props.ranch;
            payload.CompanyId = 210;
            payload.RanchBlocks = [];
            let RanchBlocks = records.map((record) => {
                let eventStartDate = new Date(DuDateUtilities.FormatDateTimeFromIso(record.EventStartDate));

                //Get the last day of the week from for the week containing the event start date
                let lastDay = eventStartDate.getDate() - eventStartDate.getDay() + 6;

                //Get the weekend Date Obj
                let weekendDate = new Date(new Date(eventStartDate).setDate(lastDay));

                //Get the weekend string in ISO format but not GMT/UTC/Zulu
                let weekendDateStr = DuDateUtilities.ToIsoDate(weekendDate, false);

                return {
                    BlockDesignator: record.MrlBlock,
                    ActiveIngredient: record.ChemicalName,
                    SourceDocumentId: record.TransactionId,
                    IsoStartDateTime: eventStartDate.toISOString(),
                    IsoEndDateTime: new Date(DuDateUtilities.FormatDateTimeFromIso(record.EventEndDate)).toISOString(),
                    IsoRecordedDateTime: new Date(DuDateUtilities.FormatDateTimeFromIso(record.CreatedDateTime)).toISOString(),
                    ApplicationType: record.ApplicationType === 'Application' ? 'Application' : 'IntentToSpray',
                    WeekEndDate: weekendDateStr
                };
            });
            payload.RanchBlocks = RanchBlocks;
            payload.TransInfo = {
                TransactionDateTime: date,
                SourceSystem: 'FACTS',
                SourceUser: loggedInUser
            };

            Middleware.Send(PAGE_TYPE, token, APIEndPoints.ADD_APPLICATION, 'DELETE', payload, { overrideRequestMapping: true })
                .then((data) => {
                    if (data & data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage(
                            `Deleted record${records.length > 1 ? 's' : ''} successfully! Please allow ${Math.max(
                                2,
                                records.length * 0.5
                            )} minutes before refreshing the results below`
                        );
                        setOnError(false);

                        let newNoActions = RanchBlocks.map((record) => record.SourceDocumentId);
                        setNoActionTransactions([...noActionTransactions, ...newNoActions]);
                    }
                })
                .catch((error) => {
                    showErrorMessage(`Failed to delete record(s)!`);
                    setOnError(true);
                });
        });
    };

    const rowCanEdit = (row) => {
        if ((row.CreatedBy || '').toLowerCase() === 'agrian' || noActionTransactions.includes(row.TransactionId)) {
            return false;
        }
        return true;
    };

    const rowCanDelete = (row) => {
        if (noActionTransactions.includes(row.TransactionId)) {
            return false;
        }
        return true;
    };

    const updateRefresh = () => {
        setRefresh(false);
    };

    return isWritable === '' ? (
        <DrcLoading></DrcLoading>
    ) : (
        <div>
            <RefreshButton onClick={refreshGrid}></RefreshButton>
            <DrcPageDataMaintenance
                className={`${classes.grid}`}
                columns={columns}
                data={props.application}
                type={PAGE_TITLE}
                textOptions={{ PageTitle: '', AddBtn: 'Add New' }}
                settings={{
                    EnableExport: false,
                    EnableAdd: isWritable,
                    EnableEdit: isWritable,
                    EnableDelete: false,
                    EnableCheckBoxDelete: {
                        access: isWritable,
                        key: 'TransactionId'
                    },
                    OverrideAdd: showAddDialog,
                    OverrideEdit: showEditDialog
                }}
                updateRefresh={updateRefresh}
                refresh={refresh}
                onAdd={onAdd}
                onCheckboxDelete={onDelete}
                rowCanDelete={rowCanDelete}
                rowCanEdit={rowCanEdit}
                columnKey={'TransactionId'}
            />
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            {addDialog ? (
                <DrcDialog
                    open={addDialog}
                    title={`${editMode ? 'Edit type:' : 'Add New'}  ${PAGE_TITLE}`}
                    buttons={
                        <div>
                            <DrcButton isSecondary onClick={onAddDialogClose} floatRight>
                                Cancel
                            </DrcButton>
                            <DrcButton isPrimary onClick={editMode ? onEdit : onAdd} floatRight disabled={!isValid}>
                                Save
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <DrcSelect
                                label={'Type'}
                                required
                                value={applicationType}
                                options={props.applicationTypeOptions}
                                onChange={(e) => {
                                    handleOnChangeOfDialogFields(e, 'ApplicationType');
                                }}
                                InputLabelProps={{ shrink: true }}
                                isDisabled={editMode}
                            />
                        </div>
                        <div className="col-xs-12">
                            <DrcSelect
                                label={'Mrl Block'}
                                required
                                value={mrlBlock}
                                isMulti={editMode ? false : true}
                                selectAllAllowed={editMode || !options.length ? false : true}
                                options={options}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'MrlBlock')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DrcSelect
                                label={'Chemical Name'}
                                required
                                value={chemicalId}
                                options={props.chemicalList}
                                isOptionDisabled={(item) => !item.isActive}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'ChemicalId')}
                                InputLabelProps={{ shrink: true }}
                                isDisabled={editMode}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DrcInput
                                label={'Comments'}
                                value={comments}
                                onChange={(e) => {
                                    handleOnChangeOfDialogFields(e, 'Comments');
                                }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 200 }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DrcSelect
                                label={'Area Type'}
                                required
                                value={areaType}
                                options={props.areaTypeOptions}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'AreaType')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DrcSelect
                                label={'Units'}
                                required
                                value={units}
                                options={props.unitOptions}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'Units')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DrcInput
                                label={'Treated Area'}
                                required
                                value={treatedArea}
                                helperText={invalidTreatedArea ? 'Enter valid number within (0.01-999.9) range' : ''}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'TreatedArea')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DrcInput
                                label={'RatePer Area'}
                                required
                                value={ratePerArea}
                                helperText={invalidRatePerArea ? 'Enter valid number within (0.01-999.9) range' : ''}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'RatePerArea')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <DrcDateTimePicker
                                label={'Event Start Date'}
                                required
                                value={eventStartDate}
                                onChange={(e) => handleOnChangeOfDialogFields(e, 'EventStartDate')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                minDate={applicationType.value === 'Application' ? oneYearInThePast : todaysDate}
                                maxDate={applicationType.value === 'Application' ? todaysDate : enableCurrentWeek}
                                disabled={editMode ? true : false}
                            />
                        </div>
                        {applicationType.value === 'Application' ? (
                            <div className="col-xs-12 col-sm-6">
                                <DrcDateTimePicker
                                    label={'Event End Date'}
                                    required
                                    value={eventEndDate}
                                    onChange={(e) => handleOnChangeOfDialogFields(e, 'EventEndDate')}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    minDate={oneYearInThePast}
                                    maxDate={enableCurrentWeek}
                                    disabled={editMode ? true : false}
                                />
                            </div>
                        ) : null}
                    </div>
                </DrcDialog>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAdmin: state.masterReducer.isAdmin,
        ranch: state.ranchOverview.ranch,
        application: state.ranchOverview.application,
        noApplication: state.ranchOverview.noApplication,
        chemicalList: state.chemicalMaintenance.chemicalList,
        ranchOverview: state.ranchOverview,
        blocks: state.ranchOverview.blocks,
        chemicalNameMapping: state.chemicalMaintenance.chemicalNameMapping,
        areaTypeOptions: state.ranchOverview.areaTypeOptions,
        unitOptions: state.ranchOverview.unitOptions,
        applicationTypeOptions: state.ranchOverview.applicationTypeOptions,
        chemicalCommodity: state.chemicalMaintenance.chemicalCommodity,
        ranchDetails: state.ranchOverview.ranchDetails
    };
};

const mapDispatchToProps = (dispatch) => ({
    setApplications: (data) => dispatch(setApplications(data)),
    setChemicalDetails: (data) => dispatch(setChemicalDetails(data))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Application)));
