import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Middleware } from 'one-ring';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton, DrcPageWarning, DrcTooltip, DrcBerryButton, DrcLegend, DrcDialog } from 'driscolls-react-components';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import ChemicalType from './ChemicalType';
import APIEndPoints from '../../../services/api';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import HoldPeriod from '../Chemical/HoldPeriod';
import chemicalMaintenanceUtilities from '../../../data/chemicalMaintenanceUtilities';
import { setChemicalDetails } from '../../../actions/chemicalMaintenanceActions';
import { setPageTitleAction } from './../../../actions/actions';

const pageTitle = 'Chemical Details';
const PAGE_TYPE = 'chemicalCommodity';

const styles = (theme) => ({
    title: {
        fontWeight: 400,
        marginBottom: '3rem',
        fontSize: theme.spacing(3)
    },
    grid: {
        margin: `-20px 0px -14px -5px` // changed to -5 from -20 left margin
    },
    selectedBerryIcon: {
        backgroundColor: '#8ec082'
    }
});

const ChemicalCommodity = (props) => {
    const { isMasterDataInitialized } = props;
    const [chemicalCommodities, setChemicalCommodities] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);
    const [showButtons, setShowbuttons] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [prevStateOptions, setPrevStateOptions] = useState('');

    useEffect(() => {
        setPrevStateOptions(props.chemicalCommodityData);
        props.setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }

        let options = props.commodityTypeOptions; //4
        let propOptions = [...props.chemicalCommodityData];

        let finalOptions = options.map((item) => {
            return {
                ChemicalCommodityId: item.value,
                ChemicalCommodity: MasterDataUtilities.MapCommodity(item.label),
                IsActive: (propOptions.find((p) => p.ChemicalCommodityId === item.value) || []).IsActive || item.isActive
            };
        });
        setChemicalCommodities(finalOptions);
        setPrevStateOptions(finalOptions);
    }, [props, isMasterDataInitialized]);

    const refreshGrid = () => {
        props.auth.getAccessToken().then((token) => {
            chemicalMaintenanceUtilities.setChemicalDetails(token, APIEndPoints.GET_CHEMICAL_DETAILS(props.chemical.value), props.setChemicalDetails);
        });
    };

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const onConfirmation = (commodities) => {
        let payload = {};
        let modifiedCommodities = checkModifiedCommodities(commodities);
        props.auth.getAccessToken().then((token) => {
            payload.ChemicalId = props.chemical.value;
            payload.Commodities = modifiedCommodities;
            payload.IsActive = true;
            payload.TransactionInfo = {
                Username: DuAuthenticationUtilities.GetUserId(token) || 'Bad Token',
                LastChangedDateTime: new Date().toISOString()
            };
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.EDIT_CHEMICAL_COMMODITY, 'POST', payload, { overrideResponseMapping: true })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Edited Chemical Commodity Successfully!');
                        refreshGrid();
                        setOnError(false);
                        setConfirm(false);
                    }
                    setShowbuttons(false);
                })
                .catch((err) => {
                    console.log(err);
                    setShowbuttons(false);
                    setChemicalCommodities(prevStateOptions);
                    showErrorMessage('Failed to edit Commodity Type');
                    setOnError(true);
                });
        });
    };

    const checkModifiedCommodities = (currentStateOptions) => {
        let modifiedOptions = (currentStateOptions || []).filter(
            (record) => record.IsActive !== (prevStateOptions || []).find((el) => el.ChemicalCommodityId === record.ChemicalCommodityId).IsActive
        );
        return modifiedOptions;
    };

    const handleCheckboxChange = (checked, value) => {
        let data = chemicalCommodities.map((item) => {
            return {
                ChemicalCommodity: item.ChemicalCommodity,
                ChemicalCommodityId: item.ChemicalCommodityId,
                IsActive: item.ChemicalCommodityId === value ? checked : item.IsActive
            };
        });

        setChemicalCommodities(data);
        setShowbuttons(true);
    };

    const handleOnSave = () => {
        onConfirmation(chemicalCommodities);
    };

    const handleOnCancel = () => {
        setChemicalCommodities(prevStateOptions);
        setShowbuttons(false);
        setConfirm(false);
    };

    const confirmation = () => {
        setConfirm(true);
    };

    const { classes } = props;

    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <DrcLegend>Type</DrcLegend>
                    <ChemicalType />
                </div>
                <div className="col-xs-12 col-md-6">
                    <DrcLegend>Commodity</DrcLegend>
                    {chemicalCommodities.length
                        ? chemicalCommodities.map((item) => {
                              return (
                                  <DrcTooltip tipText={`${item.IsActive ? 'Active' : 'Inactive'}`}>
                                      <span>
                                          <DrcBerryButton
                                              className={`${item.IsActive ? classes.selectedBerryIcon : ''}`}
                                              key={item.ChemicalCommodityId}
                                              berry={item.ChemicalCommodityId}
                                              disabled={!props.isAdmin}
                                              selected={item.IsActive}
                                              handleCheckboxChange={handleCheckboxChange}
                                          />
                                      </span>
                                  </DrcTooltip>
                              );
                          })
                        : null}
                    {showButtons ? (
                        <span>
                            <DrcButton floatLeft onClick={confirmation} style={{ margin: 0 }}>
                                Save
                            </DrcButton>
                            <DrcButton floatLeft onClick={handleOnCancel} style={{ margin: 0 }}>
                                Cancel
                            </DrcButton>
                        </span>
                    ) : null}
                    {confirm ? (
                        <DrcDialog
                            open={confirm}
                            title={`Are you sure you want to update Chemical Commodities for the chemical?`}
                            buttons={
                                <div>
                                    <DrcButton isSecondary onClick={handleOnCancel}>
                                        No
                                    </DrcButton>
                                    <DrcButton isPrimary onClick={handleOnSave}>
                                        Yes
                                    </DrcButton>
                                </div>
                            }
                        />
                    ) : null}
                </div>
            </div>
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
            <HoldPeriod />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        chemicalCommodityData: state.chemicalMaintenance.chemicalCommodity,
        chemicalTypes: state.chemicalMaintenance.chemicalTypes,
        holdPeriod: state.chemicalMaintenance.chemicalHolds,
        chemicalMaintenance: state.chemicalMaintenance,
        commodityTypeOptions: state.masterReducer.commodityTypeOptions,
        chemical: state.chemicalMaintenance.chemical,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setChemicalDetails: (data) => dispatch(setChemicalDetails(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(ChemicalCommodity)));
