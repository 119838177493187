import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';

const chemicalTypeMapping = [
    Middleware.CreateMapping('ChemicalTypeId', 'Id'),
    Middleware.CreateMapping('ChemicalType', 'ChemicalType'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateMapping('LastModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('LastModifiedDateTime', 'ModifiedDateTime', (e) => (e ? DuDateUtilities.FormatDateTimeFromIso(e) : '')),
    Middleware.CreateArrayMapping('Results')
];

const searchRanchMapping = [
    Middleware.CreateArrayResultCountMapping('totalRecords', 'Totalresults'),

    Middleware.CreateMapping('ranchNumber', 'RanchNbr'),
    Middleware.CreateMapping('overallStatus', 'OverallStatus'),
    Middleware.CreateMapping('berryType', 'BerryType'),
    Middleware.CreateMapping('growerNbr', 'GrowerNbr'),
    Middleware.CreateMapping('growerName', 'GrowerName'),
    Middleware.CreateMapping('commodityId', 'CommodityId'),
    Middleware.CreateMapping('fieldType', 'FieldType'),
    Middleware.CreateMapping('growingDistrict', 'GrowingDistrict'),
    Middleware.CreateMapping('growerGroup', 'GrowerGroup'),
    Middleware.CreateMapping('customerAcctNbr', 'CustomerAcctNbr'),
    Middleware.CreateMapping('producingArea', 'ProducingArea'),
    Middleware.CreateMapping('lastModified', 'LastModified'),
    Middleware.CreateMapping('producingAcres', 'ProducingAcres'),
    Middleware.CreateMapping('ranchName', 'Name'),
    Middleware.CreateMapping('vendorAcctNbr', 'VendorAcctNbr'),
    Middleware.CreateMapping('acres', 'Acres'),
    Middleware.CreateMapping('growingRegion', 'GrowingRegion'),
    Middleware.CreateMapping('receivingArea', 'ReceivingArea'),
    Middleware.CreateMapping('latLong', 'LatLong'),
    Middleware.CreateMapping('latitude', 'Latitude'),
    Middleware.CreateMapping('longitude', 'Longitude'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

const countryListMapping = [
    Middleware.CreateMapping('id', 'Id'),
    Middleware.CreateMapping('areaCode', 'AreaCode'),
    Middleware.CreateMapping('abbreviation', 'Abbreviation'),
    Middleware.CreateMapping('isActive', 'IsActive'),
    Middleware.CreateMapping('createdBy', 'CreatedBy'),
    Middleware.CreateMapping('createdAt', 'CreatedAt'),
    Middleware.CreateMapping('modifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('modifiedAt', 'ModifiedAt'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('chemicalType', 'GET')) {
        Middleware.RegisterMapping('chemicalType', 'GET', chemicalTypeMapping);
        Middleware.RegisterMapping('chemicalType', 'POST', chemicalTypeMapping);
    }

    if (!Middleware.CheckMappingExists('ranches', 'GET')) {
        Middleware.RegisterMapping('ranches', 'GET', searchRanchMapping);
    }

    if (!Middleware.CheckMappingExists('countryList', 'GET')) {
        Middleware.RegisterMapping('countryList', 'GET', countryListMapping);
        Middleware.RegisterMapping('countryList', 'POST', countryListMapping);
    }
}

const MasterData = {
    ConfigureMiddleWare
};

export default MasterData;
