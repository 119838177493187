import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { DrcPageDataMaintenance, DrcPageWarning, DrcTooltip, DrcDialog, DrcButton, DrcInput, DrcSelect } from 'driscolls-react-components';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import chemicalMaintenanceUtilities from '../../../data/chemicalMaintenanceUtilities';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities, DuDateUtilities } from 'driscolls-react-utilities';
import { setChemicalDetails } from '../../../actions/chemicalMaintenanceActions';

const pageTitle = 'Hold Period Maintenance';

var styles = (theme) => ({
    grid: {
        padding: 0,
        margin: 0,
        '& div[class^="DrcPanel-"]': {
            border: `none`
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            width: '350px !important'
        }
    },
    removeArrowBtns: {
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            display: 'none'
        }
    }
});

const HoldPeriod = (props) => {
    const { classes, isMasterDataInitialized } = props;

    const [errorMessage, setErrorMessage] = useState('');
    const [onError, setOnError] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [chemicalHoldId, setChemicalHoldId] = useState('');
    const [commodityDetId, setCommodityDetId] = useState('');
    const [commodityCode, setCommodityCode] = useState('');
    const [areaCode, setAreaCode] = useState('');
    const [commodity, setCommodity] = useState('');
    const [daysToShip, setDaysToShip] = useState('');
    const [invalidDaysToShip, setInvalidDaysToShip] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState('');
    const [refresh, setRefresh] = useState(false);

    const showErrorMessage = (message) => {
        setErrorMessage(message);

        //Not good way, but then the snackbar has to die!
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };
    useEffect(() => {
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
        props.auth.getAccessToken().then((token) => {
            setLoggedInUser(DuAuthenticationUtilities.GetUserId(token) || 'Bad Token');
        });
        setRefresh(true);
    }, [props, isMasterDataInitialized]);

    const mapCommoditiesToOptions = (commodities) => {
        let filteredCommodiites = (commodities || []).filter((el) => el.IsActive);
        return filteredCommodiites.map((item) => {
            return {
                label: MasterDataUtilities.MapCommodity(item.ChemicalCommodity),
                value: item.ChemicalCommodityId
            };
        });
    };

    const commodityFormatter = (row) => {
        let commodity = { label: row.Commodity, value: row.CommodityId };
        return (
            <DrcTooltip tipText={MasterDataUtilities.MapCommodity(commodity.label)}>
                <span>{MasterDataUtilities.RenderCommodityOptions(commodity)}</span>
            </DrcTooltip>
        );
    };

    const dateTimeFormatter = (value) => {
        return (
            <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
                <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
            </DrcTooltip>
        );
    };

    const cellFormatter = (value) => {
        return (
            <DrcTooltip tipText={value || ''}>
                <span>{value || ''}</span>
            </DrcTooltip>
        );
    };

    const preventInvalidNumericInput = (event) => {
        const invalidKeys = ['e', '+', '-', '.'];
        if (invalidKeys.includes(event.key.toLowerCase())) {
            event.preventDefault();
        }
    };

    const columns = [
        {
            key: 'commodityDetId',
            name: 'commodity Det Id',
            isDisabled: true,
            editDisabled: true,
            isHidden: true,
            width: 0
        },
        {
            key: 'ChemicalHoldId',
            name: 'Chemical Hold Id',
            isDisabled: true,
            editDisabled: true,
            isHidden: true,
            width: 0
        },
        {
            key: 'HoldAreaId',
            name: 'Hold Area',
            inputType: 'Select',
            selectOptions: props.countryListOptions,
            isRequired: true,
            editDisabled: true,
            width: 0
        },
        {
            key: 'AreaCode',
            name: 'Area Code',
            isRequired: false,
            width: 180,
            isDisabled: true,
            editDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.AreaCode)
        },
        {
            key: 'CommodityId',
            name: 'Commodity',
            inputType: 'Select',
            selectOptions: mapCommoditiesToOptions(props.chemicalCommodity),
            isRequired: true,
            isMulti: true,
            editIsMultiDisabled: true,
            editDisabled: true,
            width: 0
        },
        {
            key: 'Commodity',
            name: 'Commodity Name',
            isRequired: false,
            isHidden: true,
            isDisabled: true,
            editDisabled: true,
            width: 180,
            filter: true,
            columnTemplate: (row) => commodityFormatter(row)
        },
        {
            key: 'DaysToShip',
            name: 'Days To Ship',
            inputType: 'Number',
            validationType: 'number',
            isRequired: true,
            width: 180,
            className: classes.removeArrowBtns,
            minValue: 1,
            maxValue: 366,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.DaysToShip),
            onKeyPress: preventInvalidNumericInput
        },
        {
            key: 'CreatedBy',
            name: 'Created By',
            isRequired: false,
            width: 180,
            isHidden: true,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.CreatedBy)
        },
        {
            key: 'CreatedDateTime',
            name: 'Created Date Time',
            isRequired: false,
            width: 180,
            isHidden: true,
            isDisabled: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.CreatedDateTime)
        },
        {
            key: 'LastChangedBy',
            name: 'Modified By',
            isRequired: false,
            width: 180,
            isHidden: true,
            isDisabled: true,
            filter: true,
            columnTemplate: (row) => cellFormatter(row.LastChangedBy)
        },
        {
            key: 'LastChangedDateTime',
            name: 'Modified Date Time',
            isRequired: false,
            width: 180,
            isHidden: true,
            isDisabled: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.LastChangedDateTime)
        }
    ];

    const refreshGrid = () => {
        props.auth.getAccessToken().then((token) => {
            chemicalMaintenanceUtilities.setChemicalDetails(token, APIEndPoints.GET_CHEMICAL_DETAILS(props.chemical.value), props.setChemicalDetails);
        });
    };

    const checkHoldPeriodExists = (data) => {
        return (props.holdPeriods || []).find(
            (record) => record.HoldAreaId === data.HoldAreaId && record.CommodityId === data.CommodityId && record.DaysToShip === data.DaysToShip
        );
    };

    const checkDuplicateRecords = (addObj) => {
        let commodities = addObj.CommodityId;
        let holdAreaId = addObj.HoldAreaId;

        let duplicateRecords = (props.holdPeriods || []).filter(
            (el) =>
                el.HoldAreaId === holdAreaId && el.CommodityId === ((commodities || []).find((record) => record.value === el.CommodityId) || {}).value
        );

        let records = (duplicateRecords || []).map((record) => record.Commodity);
        return records.toString() || '';
    };

    const handleAdd = (addObj) => {
        let areaCode = (props.countryListOptions || []).find((el) => el.value === addObj.HoldAreaId) || {};
        let records = checkDuplicateRecords(addObj);
        if (records) {
            showErrorMessage(`Record already exists for area code - ${areaCode.label} for commodities - ${records}`);
            setOnError(true);
            return;
        }
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();

            let payload = {};

            let commodities = addObj.CommodityId.map((record) => {
                return { Commodity: record.label, CommodityId: record.value };
            });
            payload.commodities = commodities;
            payload.holdArea = areaCode.label;
            payload.holdAreaId = areaCode.value;
            payload.daysToShip = addObj.DaysToShip;
            payload.chemicalId = props.chemical.value;
            payload.isActive = true;
            payload.createdBy = loggedInUser;
            payload.createdDateTime = date;
            payload.modifiedBy = loggedInUser;
            payload.modifiedDateTime = date;

            Middleware.Send('holdPeriods', token, APIEndPoints.CHEMICAL_HOLD, 'POST', payload, {
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Added new hold period successfully!');
                        setOnError(false);
                        refreshGrid();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to add new hold period!');
                    setOnError(true);
                });
        });
    };

    const handleEdit = () => {
        let editObj = {
            ChemicalHoldId: chemicalHoldId,
            Commodity: commodityCode,
            CommodityDetId: commodityDetId,
            HoldArea: areaCode.label,
            CommodityId: commodity.value,
            HoldAreaId: areaCode.value,
            DaysToShip: daysToShip
        };

        let record = checkHoldPeriodExists(editObj);
        if (record) {
            showErrorMessage('Record already exists for area code and commodity');
            setOnError(true);
            return;
        }
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();

            let payload = {};
            let chemicalHolds = [
                {
                    ChemicalHoldId: editObj.ChemicalHoldId,
                    Commodity: editObj.Commodity,
                    ChemCommDetId: editObj.CommodityDetId,
                    CommodityId: editObj.CommodityId,
                    HoldArea: editObj.HoldArea,
                    HoldAreaId: editObj.HoldAreaId,
                    DaysToShip: editObj.DaysToShip,
                    ChemicalId: props.chemical.value,
                    IsActive: true
                }
            ];
            payload.chemicalHolds = chemicalHolds;
            payload.modifiedBy = loggedInUser;
            payload.modifiedDateTime = date;

            Middleware.Send('holdPeriods', token, APIEndPoints.CHEMICAL_HOLD, 'PUT', payload, {
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Edited hold period successfully!');
                        setOnError(false);
                        setEditMode(false);
                        refreshGrid();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showErrorMessage('Failed to edit hold period!');
                    setOnError(false);
                });
        });
    };

    const handleDelete = (delObj) => {
        let records = Object.values(delObj);
        props.auth.getAccessToken().then((token) => {
            let loggedInUser = DuAuthenticationUtilities.GetUserId(token) || 'Bad Token';
            let date = new Date().toISOString();

            let payload = {};
            payload.chemicalHolds = [];

            let chemicalHolds = records.map((record) => {
                return {
                    ChemicalHoldId: record.ChemicalHoldId,
                    Commodity: (props.commodityTypeOptions || {}).find((el) => el.value === record.CommodityId).label,
                    ChemCommDetId: record.CommodityDetId,
                    CommodityId: record.CommodityId,
                    HoldArea: record.AreaCode,
                    HoldAreaId: record.HoldAreaId,
                    DaysToShip: record.DaysToShip,
                    ChemicalId: props.chemical.value,
                    IsActive: false
                };
            });

            payload.chemicalHolds = chemicalHolds;
            payload.modifiedBy = loggedInUser;
            payload.modifiedDateTime = date;

            Middleware.Send('holdPeriods', token, APIEndPoints.CHEMICAL_HOLD, 'PUT', payload, {
                overrideResponseMapping: true
            })
                .then((data) => {
                    if (data && data.status) {
                        showErrorMessage(data.message);
                        setOnError(true);
                    } else {
                        showErrorMessage('Deleted hold period(s) successfully!');
                        setOnError(false);
                        refreshGrid();
                    }
                })
                .catch((error) => {
                    showErrorMessage('Failed to delete hold period!');
                    setOnError(true);
                });
        });
    };

    const gridDataFormatter = (data) => {
        if (data.length === 0) {
            return [];
        }

        const { DaysToShip, HoldAreaId, CommodityId } = data[0];
        let modifiedData;

        let date = new Date().toISOString();
        if (Array.isArray(CommodityId)) {
            modifiedData = (CommodityId || []).map((record) => {
                return {
                    AreaCode: ((props.countryListOptions || []).find((holdArea) => holdArea.value === HoldAreaId) || {}).label,
                    Commodity: MasterDataUtilities.RenderCommodityOptions(record),
                    DaysToShip: DaysToShip,
                    CreatedBy: loggedInUser,
                    CreatedDateTime: date,
                    LastChangeBy: loggedInUser,
                    LastChangedDateTime: date
                };
            });
        } else {
            modifiedData = [
                {
                    AreaCode: ((props.countryListOptions || []).find((holdArea) => holdArea.value === HoldAreaId) || {}).label,
                    Commodity: MasterDataUtilities.RenderCommodityOptions(
                        (props.commodityTypeOptions || []).find((el) => el.value === CommodityId) || {}
                    ),
                    DaysToShip: DaysToShip,
                    CreatedBy: loggedInUser,
                    CreatedDateTime: date,
                    LastChangeBy: loggedInUser,
                    LastChangedDateTime: date
                }
            ];
        }
        return modifiedData;
    };

    const showEditDialog = (row) => {
        setEditMode(true);
        setAreaCode({ label: row.AreaCode, value: row.HoldAreaId });
        setChemicalHoldId(row.ChemicalHoldId);
        setCommodityCode(row.CommodityCode);
        setCommodityDetId(row.CommodityDetId);
        setCommodity({ label: row.Commodity, value: row.CommodityId });
        setDaysToShip(row.DaysToShip);
        setIsValid(false);
    };
    const onCancel = () => {
        setEditMode(false);
    };

    const handleOnChangeOfDialogFields = (event) => {
        setDaysToShip(event.target.value);
        let valid = RegExp(/^[0-9]*$/);
        if (!valid.test(event.target.value) || isNaN(Number(event.target.value)) || event.target.value < 1 || event.target.value > 366) {
            setInvalidDaysToShip(true);
        } else {
            setInvalidDaysToShip(false);
        }
        if (!event.target.value) setIsValid(false);
        else setIsValid(true);
    };

    const updateRefresh = () => {
        setRefresh(false);
    };

    return (
        <div>
            <DrcPageDataMaintenance
                className={`${classes.grid} ${classes.dialog}`}
                textOptions={{ PageTitle: '', AddBtn: 'Add New' }}
                settings={{
                    EnableExport: false,
                    ShowCount: false,
                    EnableAdd: props.isAdmin,
                    EnableEdit: props.isAdmin,
                    EnableDelete: false,
                    EnableCheckBoxDelete: {
                        access: props.isAdmin,
                        key: 'ChemicalHoldId'
                    },
                    OverrideEdit: showEditDialog
                }}
                type={pageTitle}
                columns={columns}
                refresh={refresh}
                updateRefresh={updateRefresh}
                data={props.holdPeriods}
                fullWidth={true}
                onAdd={handleAdd}
                onEdit={handleEdit}
                onCheckboxDelete={handleDelete}
                gridDataFormatter={gridDataFormatter}
                columnKey={'ChemicalHoldId'}
            ></DrcPageDataMaintenance>
            <DrcDialog
                open={editMode}
                title={`Edit Type: ${pageTitle}`}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={onCancel} floatRight>
                            Cancel
                        </DrcButton>
                        <DrcButton isPrimary onClick={handleEdit} floatRight disabled={!isValid || invalidDaysToShip}>
                            Save
                        </DrcButton>
                    </div>
                }
            >
                <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect label={'Area Code'} value={areaCode} InputLabelProps={{ shrink: true }} isDisabled={true} />
                    </div>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect label={'Commodity'} value={commodity} InputLabelProps={{ shrink: true }} isDisabled={true} />
                    </div>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcInput
                            label={'Days To Ship'}
                            required
                            value={daysToShip}
                            helperText={invalidDaysToShip ? 'Enter valid number within (1-366) range without decimal' : ''}
                            onChange={(e) => handleOnChangeOfDialogFields(e)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                </div>
            </DrcDialog>
            {errorMessage ? <DrcPageWarning anchorVertical="top" message={errorMessage} isError={onError} /> : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        holdPeriods: state.chemicalMaintenance.chemicalHolds,
        countryListOptions: state.masterReducer.countryListOptions,
        commodityTypeOptions: state.masterReducer.commodityTypeOptions,
        chemicalCommodity: state.chemicalMaintenance.chemicalCommodity,
        chemical: state.chemicalMaintenance.chemical,
        isAdmin: state.masterReducer.isAdmin
    };
};

const mapDispatchToProps = (dispatch) => ({
    setChemicalDetails: (data) => dispatch(setChemicalDetails(data))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HoldPeriod)));
