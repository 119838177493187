import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';

import { setPageTitleAction } from '../../actions/actions';

import { DrcTabs } from 'driscolls-react-components';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SearchIcon from '@material-ui/icons/Search';
import TimelineIcon from '@material-ui/icons/Timeline';
import ExploreOffIcon from '@material-ui/icons/ExploreOff';

import FoodSafetyHold from './foodSafetyHold';
import InspectionHold from './inspectionHold';
import TransferHold from './transferHold';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { setHolds } from '../../actions/ranchOverviewActions';
import RanchUtilities from '../../data/RanchUtilities';
import QuarantineHold from './quarantineHold';

const pageTitle = 'Holds';

let styles = (theme) => ({
    subTabItems: {
        border: '1px solid black',
        backgroundColor: ''
    },
    panel: {
        border: `none`,
        borderRadius: 2,
        boxShadow: `0 2px 4px 0 #C3C3C3`
    },
    tabItem: {
        color: 'gray !important',
        margin: '4px 0 -5px 0',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        minWidth: theme.spacing(15),
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto'
        }
    },
    tabItemButton: {
        borderRadius: 4,
        marginRight: 8,
        border: '1px solid #C3C3C3'
    },
    activeTabItem: {
        color: `#FFFFFF !important`,
        borderBottom: `2px solid #FFFFFF`,
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
        [theme.breakpoints.down('xs')]: {}
    },
    tabIcon: {
        transform: 'translateY(4px)',
        marginRight: 4,
        [theme.breakpoints.down('xs')]: {
            transform: 'none',
            marginRight: 0,
            fontSize: '2rem'
        }
    },
    tabIconContainer: {
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    tabLabel: {
        [theme.breakpoints.down('xs')]: {
            marginTop: -6,
            display: 'block'
        }
    }
});

const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(
    window.config.OKTA_REGULAR_GROUPS || [],
    window.config.OKTA_READ_ONLY_GROUPS || [],
    window.config.OKTA_DC_USER_GROUPS || []
);

const Holds = (props) => {
    const { classes, isMasterDataInitialized } = props;

    const tabs = [
        {
            tabName: (
                <div>
                    <span className={classes.tabIconContainer}>
                        <VerifiedUserIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span className={classes.tabLabel}>Food Safety</span>
                </div>
            ),
            component: FoodSafetyHold,
            route: '/FoodSafety',
            tabIndex: 0,
            isDefault: true,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <div>
                    <span className={classes.tabIconContainer}>
                        <SearchIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span className={classes.tabLabel}>Inspection</span>
                </div>
            ),
            component: InspectionHold,
            route: '/Inspection',
            tabIndex: 1,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <div>
                    <span className={classes.tabIconContainer}>
                        <TimelineIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span className={classes.tabLabel}>Transfer</span>
                </div>
            ),
            component: TransferHold,
            route: '/Transfer',
            tabIndex: 3,
            groupsAllowed: allGroups
        },
        {
            tabName: (
                <div>
                    <span className={classes.tabIconContainer}>
                        <ExploreOffIcon className={`${classes.tabIcon}`} />
                    </span>
                    <span className={classes.tabLabel}>Quarantine</span>
                </div>
            ),
            component: QuarantineHold,
            route: '/Quarantine',
            tabIndex: 4,
            groupsAllowed: allGroups
        }
    ];

    useEffect(() => {
        props.setPageTitle(pageTitle);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            MasterDataUtilities.Redirect();
        }
        if (!props.isHoldsInitialized) getData();
    });

    const getData = () => {
        props.auth.getAccessToken().then((token) => {
            RanchUtilities.SetHoldDetails(token, props.ranch, props.setHolds);
        });
    };

    return (
        <div>
            <DrcTabs
                classes={{
                    root: classes.tabItem,
                    tabItem: `${classes.tabItem} ${classes.tabItemButton}`,
                    activeTabItem: classes.activeTabItem
                }}
                menuItems={tabs}
                registerRoutes
            ></DrcTabs>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isMasterDataInitialized: state.masterReducer.isInitialized,
        ranch: state.ranchOverview.ranch,
        isHoldsInitialized: state.ranchOverview.isHoldsInitialized
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setHolds: (data) => dispatch(setHolds(data))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Holds)));
