import { DuDateUtilities } from 'driscolls-react-utilities';
import {
    RANCH,
    SET_RANCH_DETAILS,
    SET_HOLDS,
    SET_APPLICATIONS,
    SET_COUNTRY_RESTRICTIONS,
    SET_PUR_RESTRICTIONS,
    SET_BLOCK_LIST,
    SET_MRL_BLOCKS,
    CLEAR_MRL_BLOCKS,
    SET_RANCH_LEVEL_HOLD_DETAILS,
    CLEAR_HOLD_DETAILS,
    CLEAR_RANCH
} from '../constants/constant';

const initialState = {
    ranch: '',
    holds: [],
    foodSafetyHold: [],
    inspectionHold: [],
    quarantineHold: [],
    endOfSeasonHold: [],
    transferHold: [],
    application: [],
    noApplication: [],
    intentToSpray: [],
    countryRestrictions: [],
    purRestrictions: [],
    blocks: [],
    mrlBlocks: [],
    isHoldsInitialized: false,
    noFormFiled: ''
};

const ranchOverviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case RANCH:
            let areaTypeOptions = [
                {
                    label: 'Acre',
                    value: 10
                },
                {
                    label: 'Hectare',
                    value: 11
                }
            ];

            let unitOptions = [
                {
                    label: 'Gallons',
                    value: 'gallons'
                },
                {
                    label: 'Pounds',
                    value: 'pounds'
                },
                {
                    label: 'Ounces',
                    value: 'ounces'
                },
                {
                    label: 'Tons',
                    value: 'tons'
                },
                {
                    label: 'Pints',
                    value: 'pints'
                },
                {
                    label: 'Quart',
                    value: 'quart'
                },
                {
                    label: 'Liters',
                    value: 'liters'
                },
                {
                    label: 'Milliliters',
                    value: 'milliliters'
                },
                {
                    label: 'Kilograms',
                    value: 'kilograms'
                },
                {
                    label: 'Grams',
                    value: 'grams'
                }
            ];

            let applicationTypeOptions = [
                {
                    label: 'Application',
                    value: 'Application'
                },
                {
                    label: 'IntentToSpray',
                    value: 'IntentToSpray'
                }
            ];

            return Object.assign({}, state, {
                ranch: action.payload,
                areaTypeOptions: areaTypeOptions,
                unitOptions: unitOptions,
                applicationTypeOptions: applicationTypeOptions
            });
        case CLEAR_RANCH:
            return Object.assign({}, state, {
                ...state,
                ranch: '',
                areaTypeOptions: [],
                unitOptions: [],
                applicationTypeOptions: []
            });
        case SET_RANCH_DETAILS:
            return { ...state, ranchDetails: action.payload };
        case SET_BLOCK_LIST:
            let blocks = action.payload
                .filter((item) => item.isActive)
                .map((item) => {
                    return {
                        label: item.blockDesignator,
                        value: item.blockDesignator,
                        status: item.isActive
                    };
                })
                .filter((b) => b.value);
            return { ...state, blocks };
        case SET_HOLDS:
            let foodSafetyHold = action.payload ? action.payload.foodSafetyHold : [];
            let inspectionHold = action.payload ? action.payload.inspectionHold : [];
            let quarantineHold = action.payload ? action.payload.quarantineHold : [];
            let endOfSeasonHold = action.payload ? action.payload.endOfSeasonHold : [];
            let transferHold = action.payload ? action.payload.transferHold : [];

            return Object.assign({}, state, {
                foodSafetyHold: foodSafetyHold,
                inspectionHold: inspectionHold,
                quarantineHold: quarantineHold,
                endOfSeasonHold: endOfSeasonHold,
                transferHold: transferHold,
                isHoldsInitialized: true
            });
        case SET_RANCH_LEVEL_HOLD_DETAILS:
            let data = action.payload;

            let ranchHolds = data.ranchHolds || data;
            let blockHolds = data.blockHolds || {};

            let foodSafetyHoldRanchDetails = ranchHolds.foodSafetyHold ? ranchHolds.foodSafetyHold : {};
            let inspectionHoldRanchDetails = ranchHolds.inspectionHold ? ranchHolds.inspectionHold : {};
            let quarantineHoldRanchDetails = ranchHolds.quarantineHold ? ranchHolds.quarantineHold : {};
            let transferHoldRanchDetails = ranchHolds.transferHold ? ranchHolds.transferHold : {};
            let endOfSeasonHoldDetails = ranchHolds.endOfSeasonHold ? ranchHolds.endOfSeasonHold : {};

            let foodSafetyHoldBlockDetails = blockHolds.foodSafetyHold || {};
            let inspectionHoldBlockDetails = blockHolds.inspectionHold || {};
            let quarantineHoldBlockDetails = blockHolds.quarantineHold || {};
            let transferHoldBlockDetails = blockHolds.transferHold || {};

            return {
                ...state,
                foodSafetyHoldRanchDetails,
                inspectionHoldRanchDetails,
                quarantineHoldRanchDetails,
                transferHoldRanchDetails,
                endOfSeasonHoldDetails,
                foodSafetyHoldBlockDetails,
                inspectionHoldBlockDetails,
                quarantineHoldBlockDetails,
                transferHoldBlockDetails
            };
        case CLEAR_HOLD_DETAILS:
            return {
                ...state,
                foodSafetyHoldRanchDetails: {},
                inspectionHoldRanchDetails: {},
                quarantineHoldRanchDetails: {},
                transferHoldRanchDetails: {},
                endOfSeasonHoldDetails: {},
                foodSafetyHold: [],
                inspectionHold: [],
                quarantineHold: [],
                transferHold: [],
                endOfSeasonHold: []
            };
        case SET_APPLICATIONS:
            let application = action.payload ? action.payload.application : [];
            let noApplication = action.payload ? action.payload.noApplication : [];

            return Object.assign({}, state, {
                application: application
                    ? application
                          .sort((a, b) => {
                              return new Date(b.weekendDate) - new Date(a.weekendDate);
                          })
                          .sort((a, b) => {
                              return new Date(b.createdDateTime) - new Date(a.createdDateTime);
                          })
                    : [],
                noApplication: noApplication
                    ? noApplication
                          .sort((a, b) => {
                              return new Date(b.weekendDate) - new Date(a.weekendDate);
                          })
                          .sort((a, b) => {
                              return new Date(b.createdDateTime) - new Date(a.createdDateTime);
                          })
                    : [],
                noFormFiled: action.payload.noFormFiled
            });
        case SET_COUNTRY_RESTRICTIONS:
            return { ...state, countryRestrictions: action.payload };
        case SET_PUR_RESTRICTIONS:
            let ruleSets = action.payload || [];
            let purRestrictions = [];

            if (ruleSets) {
                ruleSets.forEach((ruleSet) => {
                    (ruleSet.AreaFlags || []).forEach((areaFlag) => {
                        (areaFlag.IsoDateRanges || []).forEach((isoDateRange) => {
                            purRestrictions.push({
                                BlockDesignator: ruleSet.BlockDesignator,
                                HoldArea: areaFlag.HoldArea,
                                StartDate: isoDateRange.DateOnly
                                    ? DuDateUtilities.ToPrettyDate(isoDateRange.IsoStartDateTime)
                                    : DuDateUtilities.ToPrettyDateTime(isoDateRange.IsoStartDateTime),
                                EndDate: isoDateRange.DateOnly
                                    ? DuDateUtilities.ToPrettyDate(isoDateRange.IsoEndDateTime)
                                    : DuDateUtilities.ToPrettyDateTime(isoDateRange.IsoEndDateTime)
                            });
                        });
                    });
                });
            }

            return { ...state, purRestrictions };
        case SET_MRL_BLOCKS:
            let mrlBlocks = (action.payload || []).filter((el) => el.blockDesignator);
            return { ...state, mrlBlocks: mrlBlocks };
        case CLEAR_MRL_BLOCKS:
            return { ...state, mrlBlocks: [] };
        default:
            return state;
    }
};

export default ranchOverviewReducer;
