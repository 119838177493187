import JwtDecode from 'jwt-decode';
import DuLogger from './DuLogger';

const GetTokenFromAuth = (auth) => {
    if (typeof auth !== 'string') {
        return null;
    }

    return auth.includes('Bearer ') || auth.includes('Basic ') ? auth.split(' ')[1] : auth;
};

var GetGroups = function(token) {
    var decodedToken = DecodeToken(token);

    if (window && window.newrelic) {
        window.newrelic.setCustomAttribute('OktaUserId', decodedToken.uid || '');
    }

    return decodedToken.groups || [];
};

var IsInGroup = function(token, allowedGroups) {
    var groups = GetGroups(token);
    return groups.some((group) => allowedGroups.indexOf(group) >= 0);
};

var GetEmail = function(token) {
    var decodedToken = DecodeToken(token);
    return decodedToken.sub || decodedToken.email || '';
};

var GetOktaId = function(token) {
    var decodedToken = DecodeToken(token);
    return decodedToken.uid || '';
};

var GetUserId = function(token) {
    var decodedToken = DecodeToken(token);
    var email = decodedToken.sub || decodedToken.email || '';

    if (email.length > 0) {
        return email.substr(0, email.indexOf('@'));
    }

    return null;
};

var GetExpireDate = function(token) {
    var decodedToken = DecodeToken(token);
    var exp = decodedToken.exp || 0;

    if (exp > 0) {
        return new Date(exp * 1000);
    }

    return null;
};

var IsTokenValid = function(token, gracePeriodInMinutes) {
    var expDate = GetExpireDate(token);

    if (expDate === null) {
        return false;
    }

    const difference = expDate - new Date();

    if (difference < gracePeriodInMinutes * 60000) {
        return false;
    }

    return true;
};

var DecodeToken = function(token) {
    var decodedToken = {};

    try {
        decodedToken = JwtDecode(token);
    } catch (error) {
        DuLogger.warn('Found an invalid token, this is only okay if we are logging a user out or give them a not authorized page.', token);
    }

    return decodedToken;
};

var GetGroupScope = function(location) {
    var environment = '';
    var hostname = location ? location : window ? window.location.hostname.toLowerCase() : '';

    if (hostname.includes('dev') || hostname.includes('localhost')) {
        environment = 'dev';
    } else if (hostname.includes('stage')) {
        environment = 'stage';
    } else if (hostname.includes('uat')) {
        environment = 'uat';
    } else if (hostname.includes('qa')) {
        environment = 'qa';
    } else if (hostname.includes('tst') || hostname.includes('test')) {
        environment = 'test';
    } else {
        environment = 'prod';
    }

    return environment;
};

var ValidateTokenWithWriteScope = function(context, token, adminGroups, regularGroups) {
    if (!token || token.length <= 0) {
        if (context.state.canWrite === null || context.state.canWrite === true) {
            context.setState({ canWrite: false });
        }

        return false;
    }

    if (window && window.newrelic) {
        window.newrelic.setCustomAttribute('OktaUserId', GetOktaId(token));
    }

    adminGroups = adminGroups ? adminGroups : window && window.config ? window.config.OKTA_ADMIN_GROUPS || [] : [];
    regularGroups = regularGroups ? regularGroups : window && window.config ? window.config.OKTA_REGULAR_GROUPS || [] : [];

    if (IsInGroup(token, adminGroups) || IsInGroup(token, regularGroups)) {
        if (context.state.canWrite === null || context.state.canWrite === false) {
            context.setState({ canWrite: true });
        }
    } else {
        if (context.state.canWrite === null || context.state.canWrite === true) {
            context.setState({ canWrite: false });
        }
    }

    return true; //Return true because token is valid, don't care about canWrite for the return
};

const DuAuthenticationUtilities = {
    OnAuthRequired: ({ history }) => history.push('/'),
    GetTokenFromAuth,
    GetGroups,
    IsInGroup,
    GetEmail,
    GetOktaId,
    GetUserId,
    GetGroupScope,
    GetExpireDate,
    IsTokenValid,
    DecodeToken,
    ValidateTokenWithWriteScope
};

export default DuAuthenticationUtilities;
