import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from './data/store';
import App from './App';
import Middleware from './data/middleware/index';
import * as serviceWorker from './serviceWorker';
import { LocalizeProvider } from 'react-localize-redux';

/* Import for PWA */
import './images/ipadpro1_splash.png';
import './images/ipadpro2_splash.png';
import './images/ipadpro3_splash.png';
import './images/iphone5_splash.png';
import './images/iphone6_splash.png';
import './images/iphoneplus_splash.png';
import './images/iphonex_splash.png';
import './images/iphonexr_splash.png';
import './images/iphonexsmax_splash.png';

const renderApp = () =>
    render(
        <Provider store={Store}>
            <LocalizeProvider store={Store}>
                <App />
            </LocalizeProvider>
        </Provider>,
        document.getElementById('root')
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

Middleware.ConfigureAllMiddleware();
serviceWorker.register();
renderApp();
